import React, { useState, useEffect } from "react";
import { changeChargerPolicyPut, removePassFromCharger } from "../../../helpers/apis"
import { createPortal } from 'react-dom'
import AddPassModal from "./addPassModal";

function SelectedCharger({
    setSelectedChargerID,
    selectedCharger,
    policies,
    passes,
    refreshData
}) {

    // console.log(selectedCharger)

    const [chargerPolicy, setChargerPolicy] = useState("")
    const [chargerPasses, setChargerPasses] = useState([])
    const [addPassModal, setAddPassModal] = useState(false)
    const [error, setError] = useState("")
    const [loadingPut, setLoadingPut] = useState(false)
    const [savedPut, setSavedPut] = useState(false)

    // This allows users to remove the charger from the selected chargers section
    function addOrRemoveChargerID(id) {
        setSelectedChargerID(prev => {
            if (prev.includes(id)) {
                return prev.filter(existingId => existingId !== id);
            } else {
                return [...prev, id];
            }
        });
    }

    // This sets the state immediately so different input can have a value
    useEffect(() => {
        if (!selectedCharger.policy) return
        setChargerPolicy(selectedCharger.policy)
        setChargerPasses(selectedCharger.passes)
    }, [selectedCharger])


    async function saveNewPolicy(id, policy) {
        setSavedPut(false)
        setLoadingPut(true)
        try {
            setChargerPolicy(policy)
            const newPolicy = await changeChargerPolicyPut(id, policy)
            if (newPolicy) {
                setLoadingPut(false)
                setSavedPut(true)
                setTimeout(() => {
                    setSavedPut(false)
                }, 10000)
            }
        } catch (error) {
            setSavedPut(false)
            setLoadingPut(false)
            console.error("Error saving new policy on selected charger", error)
        }
    }

    // This function handles any errors, and deletes the pass from the charger,
    // as well as removing the state
    async function removePass(pass) {
        setSavedPut(false)
        setLoadingPut(true)
        try {
            const removedPass = await removePassFromCharger(pass, selectedCharger.chargerID)
            if (removedPass) {
                setLoadingPut(false)
                setSavedPut(true)
                setTimeout(() => {
                    setSavedPut(false)
                }, 10000)
            }
            if (!removedPass) {
                setSavedPut(false)
                setLoadingPut(false)
                setError("Error removing pass from charger")
                return
            }
            setChargerPasses(prev => {
                return prev.filter(prevPass => prevPass !== pass);
            })
            refreshData()
        } catch (err) {
            setSavedPut(false)
            setLoadingPut(false)
            console.error("Error removing pass")
        }
    }

    return (
        <div className="w-full p-2 h-full ">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <h2 className="font-semibold">{selectedCharger.name}</h2>
                </div>
                <svg onClick={() => addOrRemoveChargerID(selectedCharger.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
            </div>
            <h2 className="text-sm text-gray-700 mt-1">Base Policy:</h2>
            <select value={chargerPolicy} onChange={(e) => { saveNewPolicy(selectedCharger.chargerID, e.target.value) }} className="smaller-bold-shadow rounded-full border-[1px] p-[2px] pl-2 pr-2 border-gray-400">
                {policies?.map((policy, index) => (
                    <option className="text-sm" key={index}>{policy.policy}</option>
                ))}
            </select>
            <h2 className="text-sm text-gray-700 mt-1">Passes:</h2>
            <div className="w-full flex flex-wrap">
                <button onClick={() => setAddPassModal(true)} className="text-sm border-[1px] h-[30px] mr-1 border-primary pl-2 pr-2 p-1 rounded-lg bg-primaryAccent bg-opacity-25">Add Pass</button>
                {chargerPasses.map((pass, index) => (
                    <div key={index} className="border-[1px] border-gray-400 pl-2 pr-2 p-1 rounded-lg mr-1 mb-1 flex items-center">
                        <h2 className="text-sm">{pass}</h2>
                        <button onClick={() => removePass(pass)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 ml-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                ))}
            </div>
            {loadingPut && (
                <div className="flex items-center mt-1 justify-end">
                    <h2 className="text-sm italic text-gray-600 ml-1 mb-[1px]">saving...</h2>
                </div>
            )}
            {savedPut && (
                <div className="flex items-center mt-1 justify-end">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-green-600">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
                    </svg>
                    <h2 className="text-sm italic text-green-600 ml-1 mb-[1px]">saved</h2>
                </div>
            )}
            {addPassModal && (
                createPortal(
                    <div className={`fixed z-[9999] right-0 left-0 top-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center`}>
                        <AddPassModal
                            setAddPassModal={setAddPassModal}
                            selectedCharger={selectedCharger}
                            passes={passes}
                            refreshData={refreshData}
                        />
                    </div>
                    , document.body
                )
            )}
        </div>
    )
}

export default SelectedCharger





{/* <h2 className="text-sm text-gray-700 mt-0">Status:</h2>
<div className="flex">
    {selectedCharger?.status === "OFFLINE" && (
        <div className="h-6 pl-[6px] pr-2 max-w-32 rounded-md bg-red-100 border-[1px] border-red-700">
            <h2 className="text-sm text-red-700 italic">Offline</h2>
        </div>
    )}
    {selectedCharger?.status === "not connected" && (
        <div className="h-6 pl-[6px] pr-2 rounded-md bg-gray-100 border-[1px] border-gray-700">
            <h2 className="text-sm text-black italic">Not Connected</h2>
        </div>
    )}
    {selectedCharger?.status === "sleeping" && (
        <div className="h-6 pl-[6px] pr-2 max-w-32 rounded-md bg-gray-100 border-[1px] border-gray-700">
            <h2 className="text-sm text-gray-700 italic">Sleeping</h2>
        </div>
    )}
    {selectedCharger?.status === "connected" && (
        <div className="h-6 pl-[6px] pr-2 max-w-32 rounded-md bg-blue-100 border-[1px] border-blue-700">
            <h2 className="text-sm text-blue-700 italic">Connected</h2>
        </div>
    )}
    {selectedCharger?.status === "charging" && (
        <div className="h-6 pl-[6px] pr-2 max-w-32 rounded-md bg-green-100 border-[1px] border-green-700">
            <h2 className="text-sm text-green-700 italic">Charging</h2>
        </div>
    )}
    {selectedCharger?.status === "sleeping and not connected" && (
        <div className="h-6 pl-[6px] pr-2 max-w-32 rounded-md bg-gray-100 border-[1px] border-gray-700">
            <h2 className="text-sm text-gray-700 italic">Not Connected</h2>
        </div>
    )}
</div> */}