import { useEffect, useState } from "react"
import { debounce } from "lodash"
import { createNewPass } from "../../../helpers/apis"

function CreatePass({
    setCreatePass,
    policies,
    passes,
    refreshData
}) {

    const [nameInput, setNameInput] = useState("")
    const [chargerPolicy, setChargerPolicy] = useState("")
    const [prompt, setPrompt] = useState("")
    const [error, setError] = useState("")

    const passNames = passes.map(pass => pass.pass_name.toLowerCase())

    // This function checks the name typed in by the user to confirm
    // that no other passes have this name
    const handleNameInput = debounce((string) => {
        const searchString = string.toLowerCase()
        let passed = true
        for (let i = 0; i < passNames.length; i++) {
            if (passNames[i] === searchString) {
                setError("Error: This name is already being used");
                passed = false
                break
            }
        }
        if (passed) { setError("") }
    }, 300)

    // This sets the chargerPolicy state to the first of the user's
    // policies by default
    useEffect(() => {
        if(!policies || policies.length === 0) return
        setChargerPolicy(policies[0].policy)
    }, [policies])

    // This function handles the submit button, checks for any
    // user errors, and then sends the POST API
    async function handleSubmitButton() {
        if (nameInput === "") {
            setError("Error: Pass name input is empty")
            return
        }
        if (error !== "") return
        try {
            const newPass = await createNewPass(nameInput, chargerPolicy, prompt)
            if (newPass.message === "Success") {
                refreshData()
                setError("Success")
            }
        } catch (error) {
            setError("Error: Could not post new pass to database")
        }
    }

    return (
        <div className={`max-h-[calc(100%-56px)] w-full min-w-[300px] bg-white massive-shadow rounded-lg border-[1px] border-gray-400 mb-2`}>
            <div className="w-full h-full bg-white rounded-lg p-2 pt-1">
                <div className="flex items-center justify-between">
                    <h2 className="font-semibold mb-1">Create Pass</h2>
                    <button onClick={() => setCreatePass(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                {error !== "" && error !== "Success" && (
                    <div className="w-full bg-red-100 p-1 border-red-800 border-[1px] mb-1 rounded-md">
                        <h3 className="text-red-800 text-sm">{error}</h3>
                    </div>
                )}
                <h2 className="text-sm text-gray-700">Pass Name:</h2>
                <input
                    value={nameInput}
                    onChange={(e) => { setNameInput(e.target.value); handleNameInput(e.target.value) }}
                    className="border-[1px] border-gray-400 bg-gray-100 rounded-lg h-7 pl-2 pr-2">
                </input>
                <h2 className="text-sm mt-1 text-gray-700">Prompt:</h2>
                <textarea
                    value={prompt}
                    onChange={(e) => { setPrompt(e.target.value)}}
                    className="border-[1px] border-gray-400 bg-gray-100 text-sm rounded-lg w-full max-h-56 min-h-8 pl-2 pr-2 p-1">
                </textarea>
                <h2 className="text-sm text-gray-700 mt-0">Policy:</h2>
                <select value={chargerPolicy} onChange={(e) => { setChargerPolicy(e.target.value) }} className="smaller-bold-shadow rounded-full border-[1px] p-[2px] pl-2 pr-2 border-gray-400">
                    {policies?.map((policy, index) => (
                        <option className="text-sm" key={index}>{policy.policy}</option>
                    ))}
                </select>
                <div className="w-full h-7 flex items-center justify-end mt-1">
                    {error === "Success" ? (
                        <button disabled={true} className="text-white h-7 flex items-center bg-primary pl-2 pr-2 p-1 rounded-md">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                            </svg>
                        </button>
                    ) : (
                        <button onClick={() => handleSubmitButton()} className="text-white text-sm font-semibold bg-primary pl-2 pr-2 p-1 rounded-md">Create Pass</button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CreatePass