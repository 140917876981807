import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver'

export function exportDataArrayToExcelHistory(dataArray, chargers, simpleChargers) {
    if (!dataArray || !chargers) return;
    const uniqueChargers = [...new Set(chargers)];
    const workbook = XLSX.utils.book_new();
    uniqueChargers.forEach(chargerIndex => {
        const chargerData = dataArray[chargerIndex];
        if (chargerData && chargerData.length > 0) {
            const headers = [['time', 'value', 'kW']];
            const data = chargerData.map(dataItem => [
                dataItem.time || '',  // Default to empty string if no time
                dataItem.value || '', // Default to empty string if no value
                'kW' // Static "kW" value for each entry
            ]);
            const worksheetData = [...headers, ...data];

            // Create a worksheet from the data
            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, `${simpleChargers[chargerIndex].name}`);
        }
    });
    if (workbook.SheetNames.length === 0) {
        return;
    }
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }
    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), `historic_energy_consumption.xlsx`);
}


export function exportDataArrayToExcelReceipts(dataArray, charger) {
    if (!dataArray) return;
    const fullArray = dataArray.flat();
    if (fullArray.length === 0) return;
    const headers = Object.keys(fullArray[0]);
    const data = fullArray.map(item => headers.map(header => item[header]));
    const worksheetData = [headers, ...data];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, `${charger}`);
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }
    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), `${charger}_receipts.xlsx`);
}