import { createSlice } from '@reduxjs/toolkit'

export const mapCoordsSlice = createSlice({
    name: 'coords',
    initialState: { longitude: -111.876, latitude: 40.758 },
    reducers: {
        changeCoords: (state, action) => {
            state.longitude = action.payload.longitude;
            state.latitude = action.payload.latitude;
        }
    }
});


export const { changeCoords } = mapCoordsSlice.actions;

export default mapCoordsSlice.reducer