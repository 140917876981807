import { configureStore } from '@reduxjs/toolkit'
import chargerIdsReducer from "./reducers/chargersSlice"
import loadingSlice from './reducers/loadingSlice'
import mapCoordsSlice from './reducers/mapCoordsSlice'

export default configureStore({
    reducer: {
        chargersIds: chargerIdsReducer,
        loading: loadingSlice,
        coords: mapCoordsSlice
    }
})