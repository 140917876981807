import React, { useState, useEffect } from 'react';
import { getLoadPercent } from '../redux/selectors';
import { toggleFinished } from '../redux/reducers/loadingSlice';
import { useSelector, useDispatch } from 'react-redux';;

function InitialLoad() {

    const [loadMessage, setLoadMessage] = useState("")
    const { loadPer } = useSelector(getLoadPercent)
    const [fakePer, setFakePer] = useState(0)
    const dispatch = useDispatch()

    useEffect(() => {
        // dispatch(toggleFinished())
        // return
        // setTimeout(() => {
        //     dispatch(toggleFinished())
        // }, 400)
        // return
        const intervalId = setInterval(() => {
            const randomAddition = Math.floor(Math.random() * 7) + 1;
            setFakePer(prevFakePer => {
                let newFakePer = prevFakePer + randomAddition;
                if (newFakePer < 25) {
                    setLoadMessage("Fetching sensors")
                    if (newFakePer < loadPer) {
                        return loadPer
                    }
                }
                if (newFakePer < 50 && newFakePer >= 25) {
                    setLoadMessage("Populating map")
                    if (newFakePer < loadPer) {
                        return loadPer
                    }
                }
                if (newFakePer < 75 && newFakePer >= 50) {
                    setLoadMessage("Building graphs")
                    if (newFakePer < loadPer) {
                        return loadPer
                    }
                }
                if (newFakePer < 100 && newFakePer >= 75) {
                    setLoadMessage("Rendering page")
                    if (newFakePer < loadPer) {
                        return loadPer
                    }
                }
                if (newFakePer >= 95) {
                    clearInterval(intervalId);
                }
                return newFakePer;
            });
        }, 30);
        // setTimeout(() => {
        //     setLoadMessage("Finished loading")
        //     clearInterval(intervalId)
        //     setTimeout(() => {
        //         dispatch(toggleFinished())
        //     }, 400)
        // }, 1000)
        if (loadPer === 100) {
            setLoadMessage("Finished loading")
            clearInterval(intervalId)
            setFakePer(100)
            setTimeout(() => {
                dispatch(toggleFinished())
            }, 400)
        }
        return () => clearInterval(intervalId);
    }, [loadPer]);

    return (
        <div className="fixed z-[9999] right-0 top-0 left-0 bottom-0 bg-baseWhite flex justify-center items-center">
            <div className="text-center justify-center items-center flex-col flex bg-white w-[400px] h-[450px] rounded-lg shadow-md">
                <img className='w-[150px] h-[150px] -mt-24 mb-10' src='/gridElevatedLogo.svg'></img>
                <h2 className="text-md mt-4 font-semibold font-sans italic">{loadMessage}... {fakePer}%</h2>
                <div className="w-64 h-4 mt-3 bg-gray-200 rounded-xl">
                    <div style={{ width: `${fakePer}%` }} className="w-16 h-4 pulsating-background rounded-xl duration-100 ease-in-out">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default InitialLoad;