import { useState, useEffect } from "react";
import { debounce } from 'lodash';

function SubMenu({
    setSelectedChargerID,
    selectedChargerID,
    setSelectedPolicyID,
    selectedPolicyID,
    setSelectedPassesID,
    selectedPassesID,

    setCreatePass,

    chargers,
    policies,
    passes
}) {

    const [chargerSearchResults, setChargerSearchResults] = useState([])
    const [policySearchResults, setPolicySearchResults] = useState([])
    const [passesSearchResults, setPassesSearchResults] = useState([])

    const [expandedNumber, setExpandedNumber] = useState(0)
    const [chargersExpanded, setChargersExpanded] = useState(false)
    const [policiesExpanded, setPoliciesExpanded] = useState(false)
    const [passesExpanded, setPassesExpanded] = useState(false)

    // This ensures all of the user's chargers and policies are
    // being displayed when the page is loaded, or if they're changed
    useEffect(() => {
        if (!chargers || !policies) return
        setChargerSearchResults(chargers)
        setPolicySearchResults(policies)
        setPassesSearchResults(passes)
    }, [chargers, policies, passes])

    // These three functions change what results are being showed based
    // on the search string entered by the user (its a search bar).
    // It uses debounce to ensure better performance
    const changeChargerSearchResults = debounce((string) => {
        const searchString = string.toLowerCase()
        const filteredResults = chargers.filter(
            charger => charger.name.toLowerCase().includes(searchString.toLowerCase())
        )
        setChargerSearchResults(filteredResults)
    }, 200)
    const changePolicySearchResults = debounce((string) => {
        const searchString = string.toLowerCase()
        const filteredResults = policies.filter(
            policy => policy.policy.toLowerCase().includes(searchString.toLowerCase())
        )
        setPolicySearchResults(filteredResults)
    }, 200)
    const changePassesSearchResults = debounce((string) => {
        const searchString = string.toLowerCase()
        const filteredResults = passes.filter(
            pass => pass.pass_name.toLowerCase().includes(searchString.toLowerCase())
        )
        setPassesSearchResults(filteredResults)
    }, 200)

    // These three functions all do the same thing. They add or remove
    // charger, policy, and pass ids from the state to be shown over the map
    function addOrRemoveChargerID(id) {
        setSelectedChargerID(prev => {
            if (prev.includes(id)) {
                return prev.filter(existingId => existingId !== id);
            } else {
                return [...prev, id];
            }
        });
    }
    function addOrRemovePolicyID(id) {
        if (selectedPolicyID === id) {
            setSelectedPolicyID(-1)
        } else {
            setSelectedPolicyID(id)
        }
    }
    function addOrRemovePassesID(id) {
        setSelectedPassesID(prev => {
            if (prev.includes(id)) {
                return prev.filter(existingId => existingId !== id);
            } else {
                return [...prev, id];
            }
        })
    }

    // This function calculates how many of the lists have been opened
    useEffect(() => {
        let rowNum = 0
        chargersExpanded ? rowNum += 1 : rowNum += 0
        policiesExpanded ? rowNum += 1 : rowNum += 0
        passesExpanded ? rowNum += 1 : rowNum += 0
        setExpandedNumber(rowNum)
    }, [chargersExpanded, policiesExpanded, passesExpanded])

    return (
        <div className="w-full h-full bg-gray-100 flex flex-col border-r-[1px] border-gray-400">
            {/* Chargers Section */}
            <div className={`
                ${expandedNumber === 1 && chargersExpanded && "h-full overflow-x-hidden overflow-y-scroll dashboardScrollBar"}
                ${expandedNumber === 2 && chargersExpanded && "h-[50%] overflow-x-hidden overflow-y-scroll dashboardScrollBar"}
                ${expandedNumber === 3 && chargersExpanded && "h-[33.33%] overflow-x-hidden overflow-y-scroll dashboardScrollBar"}    
                flex flex-col w-full bg-white relative`}>
                <div className="sticky top-0 p-1 pl-2 pr-2 pb-2 border-b-[1px] border-gray-300 bg-white border-t-[1px] z-30">
                    <div className="flex items-center justify-between">
                        <button className="flex items-center" onClick={() => setChargersExpanded(!chargersExpanded)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${chargersExpanded ? "rotate-90" : ""} size-4`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                            <h2 className="font-semibold text-md ml-1">Your Chargers</h2>
                        </button>
                    </div>
                    <input onChange={(e) => changeChargerSearchResults(e.target.value)} type="text" placeholder="Search for a charger" className={`${!chargersExpanded && "hidden"} text-sm pl-2 pr-2 h-7 mt-1 ml-5 rounded-full bg-gray-100 border-[1px] border-gray-500`}></input>
                </div>
                {chargersExpanded && (
                    <>
                        <div className="h-full bg-gray-100">
                            {chargerSearchResults?.map((charger, index) => (
                                <div key={index} onClick={() => addOrRemoveChargerID(charger.id)}
                                    className={`${selectedChargerID.includes(charger.id) ? "bg-blue-100 border-secondaryAccent border-[1px]" : "bg-gray-100 border-b-[1px] border-gray-300"} p-1 duration-100 ease-in-out cursor-pointer`}>
                                    <h2 className=" text-[13px] ml-6">{charger.name}</h2>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>

            {/* Policies Section */}
            <div className={`
                ${expandedNumber === 1 && policiesExpanded && "h-full overflow-x-hidden overflow-y-scroll dashboardScrollBar"}
                ${expandedNumber === 2 && policiesExpanded && "h-[50%] border-t-[1px] overflow-x-hidden overflow-y-scroll dashboardScrollBar"}
                ${expandedNumber === 3 && policiesExpanded && "h-[33.33%] border-t-[1px] overflow-x-hidden overflow-y-scroll dashboardScrollBar"}    
                flex flex-col w-full bg-white relative border-gray-300`}>
                <div className="sticky top-0 p-1 pl-2 pr-2 pb-2 border-b-[1px] border-gray-300 bg-white border-t-[0px] z-30">
                    <div className="flex items-center justify-between">
                        <button className="flex items-center" onClick={() => setPoliciesExpanded(!policiesExpanded)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${policiesExpanded ? "rotate-90" : ""} size-4`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                            <h2 onClick={() => setPoliciesExpanded(!policiesExpanded)} className="font-semibold text-md ml-1">Your Policies</h2>
                        </button>
                        <button onClick={() => setSelectedPolicyID(-2)} className="flex items-center">
                            <h2 className="text-xs">Create</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                        </button>
                    </div>
                    <input type="text" onChange={(e) => changePolicySearchResults(e.target.value)} placeholder="Search for a policy" className={`${!policiesExpanded && "hidden"} text-sm pl-2 pr-2 h-7 mt-1 ml-5 rounded-full bg-gray-100 border-[1px] border-gray-500`}></input>
                </div>
                <div className="h-full ">
                    {policiesExpanded && (
                        <>
                            <div className="bg-gray-100 h-full">
                                {policySearchResults?.map((data, index) => (
                                    <div key={index} onClick={() => addOrRemovePolicyID(data.id)}
                                        className={`${selectedPolicyID === data.id ? "bg-blue-100 border-secondaryAccent border-[1px]" : "bg-gray-100 border-b-[1px] border-gray-300"} p-1 duration-100 ease-in-out cursor-pointer`}>
                                        <h2 className="text-[13px] ml-6">{data.policy}</h2>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Passes Section */}
            <div className={`
                ${expandedNumber === 1 && passesExpanded && "h-full overflow-x-hidden overflow-y-scroll dashboardScrollBar"}
                ${expandedNumber === 2 && passesExpanded && "h-[50%] border-t-[1px] overflow-x-hidden overflow-y-scroll dashboardScrollBar"}
                ${expandedNumber === 3 && passesExpanded && "h-[33.33%] border-t-[1px] overflow-x-hidden overflow-y-scroll dashboardScrollBar"}    
                flex flex-col w-full bg-white relative border-gray-300`}>
                <div className="sticky top-0 p-1 pl-2 pr-2 pb-2 border-b-[1px] border-gray-300 bg-white border-t-[0px] z-30">
                    <div className="flex items-center justify-between">
                        <button className="flex items-center" onClick={() => setPassesExpanded(!passesExpanded)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${passesExpanded ? "rotate-90" : ""} size-4`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                            <h2 onClick={() => setPassesExpanded(!passesExpanded)} className="font-semibold text-md ml-1">Your Passes</h2>
                        </button>
                        <button onClick={() => setCreatePass(true)} className="flex items-center">
                            <h2 className="text-xs">Create</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                        </button>
                    </div>
                    <input type="text" onChange={(e) => changePassesSearchResults(e.target.value)} placeholder="Search for a pass" className={`${!passesExpanded && "hidden"} text-sm pl-2 pr-2 h-7 mt-1 ml-5 rounded-full bg-gray-100 border-[1px] border-gray-500`}></input>
                </div>
                {passesExpanded && (
                    <>
                        <div className="bg-gray-100 h-full">
                            {passesSearchResults?.map((data, index) => (
                                <div key={index} onClick={() => addOrRemovePassesID(data.id)}
                                    className={`${selectedPassesID.includes(data.id) ? "bg-blue-100 border-secondaryAccent border-[1px]" : "bg-gray-100 border-b-[1px] border-gray-300"} p-1 duration-100 ease-in-out cursor-pointer`}>
                                    <h2 className="text-[13px] ml-6">{data.pass_name}</h2>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default SubMenu
