import React, { useState, useRef, useEffect, Fragment } from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import '@splidejs/splide/dist/css/splide.min.css';

import NewContainer from "./newContainer";

function HistorySplide({
    receipts,
    chargerNameIdPair
}) {

    const [splideOptions, setSplideOptions] = useState({
        rewind: false,
        width: "100%",
        height: "100%",
        gap: 16,
        perPage: 4,
        perMove: 1,
        type: 'slide',
        pagination: false
    })

    const splideRef = useRef()
    const splideParentDiv = useRef()

    // These simple callbacks tell the splide to go forward or backwards
    const goNext = () => splideRef.current.splide.go("+");
    const goPrev = () => splideRef.current.splide.go("-");

    // useEffect(() => {
    //     const handleResize = () => {
    //         if (splideParentDiv.current) {
    //             const newWidth = splideParentDiv.current.offsetWidth;

    //             if (newWidth <= 370) {
    //                 setSplideOptions(options => ({ ...options, perPage: 4 }));
    //             } else if (newWidth <= 500) {
    //                 setSplideOptions(options => ({ ...options, perPage: 5 }));
    //             } else if (newWidth <= 700) {
    //                 setSplideOptions(options => ({ ...options, perPage: 5 }));
    //             } else if (newWidth <= 850) {
    //                 setSplideOptions(options => ({ ...options, perPage: 6 }));
    //             } else {
    //                 setSplideOptions(options => ({ ...options, perPage: 7 }));
    //             }

    //             setSplideOptions(options => ({ ...options, width: newWidth }));
    //         }
    //     };
    //     if (splideParentDiv.current) {
    //         handleResize();
    //     }
    //     const resizeObserver = new ResizeObserver(handleResize);
    //     if (splideParentDiv.current) {
    //         resizeObserver.observe(splideParentDiv.current);
    //     }
    //     window.addEventListener('resize', handleResize);
    //     return () => {
    //         if (splideParentDiv.current) {
    //             resizeObserver.unobserve(splideParentDiv.current);
    //         }
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, [splideParentDiv, setSplideOptions,]);


    return (
        <div className="w-full h-full flex">
            <div className="w-16 h-full flex items-center justify-center ">
                <button onClick={() => goPrev()} className="rounded-full p-2 bg-primaryAccent bg-opacity-50 smaller-bold-shadow-splide">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                    </svg>
                </button>
            </div>
            <div ref={splideParentDiv} className="w-[calc(100%-128px)] h-full">
                <Splide
                    ref={splideRef}
                    options={splideOptions}
                    hasTrack={false}
                >
                    <SplideTrack>
                        {Object.keys(receipts?.data).map((charger, index) => (
                            <Fragment key={index}>
                                <SplideSlide className="">
                                    <div className="h-[calc(100vh-88px)]">
                                        <NewContainer
                                            charger={charger}
                                            receipts={receipts}
                                            index={index}
                                            chargerNameIdPair={chargerNameIdPair}
                                        />
                                    </div>
                                </SplideSlide>
                                {/* <SplideSlide className="">
                                    <div className="h-[calc(100vh-88px)]">
                                        <NewContainer
                                            charger={charger}
                                            receipts={receipts}
                                            index={index}
                                            chargerNameIdPair={chargerNameIdPair}
                                        />
                                    </div>
                                </SplideSlide> */}
                            </Fragment>
                        ))}
                    </SplideTrack>
                    <div className="splide__arrows hidden"></div>
                </Splide>
            </div>
            <div className="w-16 h-full flex items-center justify-center">
                <button onClick={() => goNext()} className="smaller-bold-shadow-splide rounded-full p-2 bg-primaryAccent bg-opacity-50">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                    </svg>
                </button>
            </div>
        </div>
    )
}

export default HistorySplide