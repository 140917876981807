import React, { useState, useEffect } from "react"
import { deleteChargerPolicy, postNewChargerPolicy, updateChargerPolicy } from "../../../helpers/apis"
import { newPolicyZeroArrays, newPolicyFormEnergy, newPolicyFormParking, newPolicyFormSleeping } from "../../../helpers/random"
import { getPricesForEachDay, groupDaysByValues } from "../../../helpers/payment"
import { createPortal } from "react-dom"
import { deletePolicy } from "../../../helpers/apis"

import SleepingInput from "./policyFormComponents/sleepingInput"
import ParkingInput from "./policyFormComponents/parkingInput"
import EnergyInput from "./policyFormComponents/energyInput"
import ConfirmDelete from "../confirmDelete"

// import DatePicker from "react-datepicker"
// import "react-datepicker/dist/react-datepicker.css";

function PolicyForm({
    setSelectedPolicyID,
    selectedPolicyID,
    policyToBeShown,
    newPolicy,
    passes,
    chargers,
    policies,
    refreshData
}) {

    const [policyName, setPolicyName] = useState("new policy")
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    const [energyArray, setEnergyArray] = useState([])
    const [updatedEnergyArray, setUpdatedEnergyArray] = useState([])
    const [parkingArray, setParkingArray] = useState([])
    const [updatedParkingArray, setUpdatedParkingArray] = useState([])
    const [sleepingArray, setSleepingArray] = useState([])
    const [updatedSleepingArray, setUpdatedSleepingArray] = useState([])

    const [error, setError] = useState("")

    // This useEffect checks to see if the user opened a new policy, 
    // or if the policy already exists
    useEffect(() => {
        if (selectedPolicyID === -2) {
            setEnergyArray(newPolicyFormEnergy)
            setParkingArray(newPolicyFormParking)
            setSleepingArray(newPolicyFormSleeping)
            setPolicyName("New Policy")
            return
        }
        if (policyToBeShown?.data?.length !== 0 && policyToBeShown?.data?.energy) {
            setEnergyArray(policyToBeShown.data.energy)
            setParkingArray(policyToBeShown.data.parking)
            setSleepingArray(policyToBeShown.data.sleeping)
            setPolicyName(policyToBeShown?.policy)
        }
    }, [policyToBeShown, selectedPolicyID])

    // Function that formats the policy data into an acceptable format for the server
    // while also handling all errors, and if the user is submitting a new form
    // or updating an existing form 
    function submitOrSaveForm(type) {
        // setLoadingSubmit(true)
        // setFinishedSubmitting(false)
        setError("loading")
        let data = {
            name: policyName,
            values: updatedEnergyArray,
            valuesP: updatedParkingArray,
            valuesS: updatedSleepingArray
        }
        data.values.forEach((object) => {
            object.parking = Array(24).fill(0)
            object.green = ""
            object.idle = 20
            object.member = 0
        })
        data.valuesP.forEach((object) => {
            object.energy = Array(24).fill(0)
            object.green = ""
            object.idle = 20
            object.member = 0
        })
        data.valuesS.forEach((object) => {
            object.member = 0
            object.chargeType = "time"
            object.sType = "time"
        })
        if (type === "submit") {
            async function postNewPolicy() {
                try {
                    const postedPolicy = await postNewChargerPolicy(data)
                    if (!postedPolicy) {
                        setError("error")
                    } else {
                        setError("success")
                        refreshData()
                        setTimeout(() => {
                            setError("")
                            refreshData()
                        }, 3500)
                    }
                } catch (error) {
                    console.error("error posting new policy in policy form", error)
                    setError("error")
                }
            }
            postNewPolicy()
        } else {
            async function updatePolicy() {
                try {
                    const postedPolicy = await updateChargerPolicy(data)
                    if (!postedPolicy) {
                        setError("error")
                    } else {  
                        setError("success")
                        refreshData()
                        setTimeout(() => {
                            setError("")
                            refreshData()
                        }, 3500)
                    }
                } catch (error) {
                    console.error("error posting new policy in policy form", error)
                    setError("error")
                }
            }
            updatePolicy()
        }
        refreshData()
    }

    return (
        <div className="w-full h-full overflow-y-scroll policyFormScrollBar rounded-lg">
            <div className="flex items-center justify-between absolute w-[493px] pl-2 pr-2 pt-1 pb-1 rounded-tl-lg bg-white border-b-[1px] border-gray-400">
                <div className="flex items-center">
                    {newPolicy ? (
                        <h2 className="font-semibold">New Policy Form</h2>
                    ) : (
                        <h2 className="font-semibold">Edit Policy Form</h2>
                    )}
                </div>
                <svg onClick={() => setSelectedPolicyID(-1)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
            </div>
            {error === "loading" && (
                <div className="w-full h-full p-2 pt-10">
                    <div className="w-full h-full bg-blue-50 border-[1px] border-blue-400 rounded-lg flex items-center justify-center">
                        {/* <div className="w-36 h-72 bg-white">
                        </div>     */}
                        <h2 className="text-lg font-semibold text-secondary">Submitting form...</h2>
                    </div>
                </div>
            )}
            {error === "success" && (
                <div className="w-full h-full p-2 pt-10">
                    <div className="w-full h-full bg-green-50 border-[1px] border-green-400 rounded-lg flex items-center justify-center">
                        {/* <div className="w-36 h-72 bg-white">
                    </div>     */}
                    <div className="text-center">
                        <h2 className="text-lg font-semibold text-primary">Policy has been updated successfully</h2>
                        <h3 className="text-sm">Refresh page to see changes</h3>
                    </div>    
                    </div>
                </div>
            )}
            {error === "" && (
                <>
                    <div className=" mt-9 pl-2 pr-2">
                        <h2 className="mt-2 text-gray-800 text-sm">Name:</h2>
                        <input value={policyName} onChange={(e) => setPolicyName(e.target.value)} className="border-[1px] border-gray-400 rounded-[4px] pl-1 pr-1 bg-gray-100" type="text"></input>
                        <h2 className="mt-2 text-gray-800 text-sm">Energy Pricing:</h2>
                        <EnergyInput
                            energyArray={energyArray}
                            setUpdatedEnergyArray={setUpdatedEnergyArray} />
                        <ParkingInput
                            parkingInfo={parkingArray}
                            setAlteredParkingValues={setUpdatedParkingArray} />
                        <SleepingInput
                            sleepingArray={sleepingArray}
                            setUpdatedSleepingArray={setUpdatedSleepingArray}
                        />
                    </div>
                    <div className="h-16 w-full flex items-end justify-between mb-2 pl-2 pr-2">
                        <button className="text-sm font-semibold bg-red-300 rounded-md text-black p-1 pl-2 pr-2" onClick={() => setShowConfirmDelete(true)}>Delete</button>
                        {selectedPolicyID === -2 ? (
                            <button className="text-sm font-semibold bg-primary rounded-md text-white p-1 pl-2 pr-2" onClick={() => submitOrSaveForm("submit")}>Submit</button>
                        ) : (
                            <button className="text-sm font-semibold bg-primary rounded-md text-white p-1 pl-2 pr-2" onClick={() => submitOrSaveForm("save")}>Save</button>
                        )}
                    </div>
                </>
            )}
            {showConfirmDelete && (
                createPortal(
                    <div className={`fixed z-[9999] right-0 left-0 top-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center`}>
                        <ConfirmDelete
                            setShowConfirmDelete={setShowConfirmDelete}
                            chargers={chargers}
                            title={"Delete Policy"}
                            name={policyName}
                            refreshData={refreshData}
                            passes={passes}
                            policies={policies}
                        />
                    </div>
                    , document.body
                )
            )}
        </div>
    )
}

export default PolicyForm