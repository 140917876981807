import React, { useEffect, useState, useRef, Fragment } from "react";
import { exportDataArrayToExcelHistory } from "../../helpers/downloadToExcel";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { getFormattedDateHistory, parseCustomDateHistory } from "../../helpers/timeFunctions";
import { format } from 'date-fns';
import { fetch90DayHistoryChargers } from "../../helpers/apis";
import { formatForHistoryGraph } from "../../helpers/componentHelpers/dashboardGraphs";
import { debounce } from 'lodash';

import LineGraphs from "../graphComponents/graphComponents/lineGraphs";
import ChargerSplide from "./graphComponents/splide";
import HistoryGraph from "./graphComponents/historyGraph";

function GraphIntermediate({
    combinedNoDuplicatesArray,

    clustersToShowSetter,
    clustersToShow,
    chargersToShowSetter,
    chargersToShow,

    clusterNames,
    simpleChargers,

    formalLotNames,

    title,
    type
}) {


    const [showCumulativeEnergyOptions, setShowCumulativeEnergyOptions] = useState(false)
    const [historyGraphData, setHistoryGraphData] = useState([])
    const [graphDimensions, setGraphDimensions] = useState({ width: 0, height: 0, marginTop: 4, marginRight: 10, marginBottom: 40, marginLeft: 60 })
    const [searchString, setSearchString] = useState("")
    const [error, setError] = useState("")
    // 0 = show graph
    // 1 = error
    // 2 = no cluster selected
    // 3 = loading data
    const [displayType, setDisplayType] = useState(0)
    const graphRef = useRef()

    const [startingDateHistoryGraph, setStartingDateHistoryGraph] = useState(parseCustomDateHistory(getFormattedDateHistory(90)).toISOString().slice(0, 10))
    const [endingDateHistoryGraph, setEndingDateHistoryGraph] = useState(parseCustomDateHistory(getFormattedDateHistory(0)).toISOString().slice(0, 10))

    // This simple function ensures the buttons in the dropdown adds or removes
    // a group based on the current state
    function addOrRemoveSelectedCluster(cluster) {
        clustersToShowSetter(prev => {
            if (prev.includes(cluster)) {
                chargersToShowSetter([])
                localStorage.setItem(`${cluster}-${title}`, false)
                return prev.filter(existingGroup => existingGroup !== cluster);
            } else {
                chargersToShowSetter([0, 1, 2])
                localStorage.setItem(`${cluster}-${title}`, true)
                return [...prev, cluster];
            }
        });
    }

    // useEffect(() => {
    //     console.log(displayType)
    // }, [displayType])

    useEffect(() => {
        setError("")
        if (!combinedNoDuplicatesArray || combinedNoDuplicatesArray.length === 0) {
            if (title !== "Historic Energy Consumption") {
                setError("This graph contains no data")
                setDisplayType(1)
                return
            }
        }
        if (!clustersToShow || !chargersToShow) {
            // console.log("Error with clusters chargers array in graph", title)
            setError("Error handling chargers and clusters to show array")
            setDisplayType(1)
            return
        }
        if (clustersToShow.length === 0) {
            // console.log("No clusters are selected", title)
            setDisplayType(2)
            return
        }
        setDisplayType(0)
    }, [combinedNoDuplicatesArray, clustersToShow, chargersToShow, clusterNames])

    // console.log(chargersToShow)

    // This function is only used for the "Historic Energy Consumption graph"
    useEffect(() => {
        if (title !== "Historic Energy Consumption") return
        async function fetchHistory() {
            try {
                const history = await fetch90DayHistoryChargers(startingDateHistoryGraph, endingDateHistoryGraph)
                const formattedData = formatForHistoryGraph(history)
                console.log(formattedData)
                setHistoryGraphData(formattedData)
            } catch (error) {
                setError("Error fetching history API")
                console.error("Error fetching Historic Energy Consumption", error)
                return
            }
        }
        fetchHistory()
    }, [endingDateHistoryGraph, startingDateHistoryGraph])

    // This function checks to see if the cluster was activated last session (via localStorage)
    // and eliminates the need for the clunky open on render function I had
    useEffect(() => {
        if (!clusterNames) return
        clusterNames.forEach((cluster) => {
            try {
                let localStorageCluster = JSON.parse(localStorage.getItem(`${cluster}-${title}`))
                if (localStorageCluster) {
                    clustersToShowSetter(prev => [...prev, cluster])
                    setError("")
                }
                // console.log(error)
                // console.log(localStorageCluster)
            } catch (error) {
                console.error("error trying to check local storage in graph intermediate")
            }
        })
    }, [clusterNames])

    // This ResizeObserver observes the width and height of the lineGraph's
    // parent div, ensuring the graph can handle different viewport sizes, and 
    // if users change the size of the page
    useEffect(() => {
        if (graphRef.current) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const parentDiv = entry.contentRect
                    let newHeight = parentDiv.height
                    let newWidth = parentDiv.width
                    setGraphDimensions(graphDimensions => ({ ...graphDimensions, height: newHeight, width: newWidth }))
                    // console.log(newWidth)
                }
            })
            resizeObserver.observe(graphRef.current);
            return () => resizeObserver.disconnect();
        }
    }, [graphRef])

    // This changes the search string for the select clusters table search
    const changeSearchString = debounce((searchString) => {
        const string = searchString.toLowerCase()
        setSearchString(string)
    }, 200)

    console.log(simpleChargers)

    return (
        <div className="col-span-6 rounded-lg -mt-3 bg-white h-[458px] small-bold-shadow p-3 relative mb-3">
            <div className={` flex justify-between h-8 items-center border-b-[0px] border-gray-300 rounded-tr-lg rounded-tl-lg  dark:border-gray-800 pb-2 w-full p-2`}>
                <h2 className="relative font-semibold text-md text-black dark:text-textWhite font-sans">{title}</h2>
                <div className="flex items-center">
                    {/* <button onClick={() => exportDataArrayToExcelHistory(combinedNoDuplicatesArray, simpleChargers)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer  dark:text-textWhite w-[18px] h-[18px] mr-1 hover:scale-105">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>
                    </button>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-[18px] h-[18px] text-black dark:text-textWhite hover:scale-105 cursor-pointer`}
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                    </svg> */}
                </div>
            </div>
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <button
                        name="startTime"
                        onClick={() => setShowCumulativeEnergyOptions(!showCumulativeEnergyOptions)}
                        className="border-[1px] flex items-center dark:bg-sidebarDark dark:border-[#555555] bg-white -mt-[1px] dark:bg-lightestBaseGray h-7 pl-2 pr-2 ml-2 rounded-full smaller-bold-shadow border-gray-400 dark:border-black text-sm text-black dark:text-secondaryTextWhite"
                    >
                        Parking Clusters
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4 w-4 ml-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>
                    </button>
                    {title === "Historic Energy Consumption" && (
                        <>
                            <DatePicker
                                selected={startingDateHistoryGraph}
                                onChange={(date) => setStartingDateHistoryGraph(format(date, 'yyyy-MM-dd'))}
                                dateFormat="yyyy-MM-dd"
                                className="border-[1px] w-24 flex items-center ml-6 dark:bg-sidebarDark dark:border-[#555555] bg-white dark:bg-lightestBaseGray h-7 pl-2 pr-2 ml-2 -mt-[4px] rounded-full smaller-bold-shadow border-gray-400 dark:border-black text-sm text-black dark:text-secondaryTextWhite"
                            />
                            <DatePicker
                                selected={endingDateHistoryGraph}
                                onChange={(date) => setEndingDateHistoryGraph(format(date, 'yyyy-MM-dd'))}
                                dateFormat="yyyy-MM-dd"
                                className="border-[1px] w-24 flex items-center dark:bg-sidebarDark dark:border-[#555555] bg-white dark:bg-lightestBaseGray h-7 pl-2 pr-2 ml-2 -mt-[4px] rounded-full smaller-bold-shadow border-gray-400 dark:border-black text-sm text-black dark:text-secondaryTextWhite"
                            />
                        </>
                    )}
                </div>
                {title === "Historic Energy Consumption" && (
                    <button onClick={() => exportDataArrayToExcelHistory(historyGraphData, chargersToShow, simpleChargers)} className="">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>
                    </button>
                )}
            </div>
            {showCumulativeEnergyOptions && (
                // <div className="h-48 w-72 bg-white dropdown-large-shadow rounded-lg border-[1px] border-gray-400 absolute mt-[4px] ml-2 z-30 overflow-y-scroll parkingClusterDropdownScrollbar">
                // </div>
                <div className="h-72 w-72 bg-baseWhite chargerSelectorDropdown rounded-md border-[1px] border-gray-400 absolute mt-2 ml-2 z-30">
                    <div className="p-2 bg-white rounded-tr-md rounded-tl-md shadow-sm z-30 border-b-[1px] border-gray-300">
                        <input type="text" onChange={(e) => changeSearchString(e.target.value)} className="w-full rounded-full h-8 border-[1px] border-gray-500 bg-white pl-2 pr-2 text-sm" placeholder="Search for a cluster"></input>
                    </div>
                    <div className="w-full h-[calc(100%-49px)] rounded-br-md rounded-bl-md overflow-y-scroll ">
                        {clusterNames?.map((name, index) => (
                            <button key={index} onClick={() => addOrRemoveSelectedCluster(name)} className={`${clustersToShow.includes(name) ? "bg-blue-100" : ""} ${name.toLowerCase().includes(searchString.toLowerCase()) || formalLotNames[name].toLowerCase().includes(searchString.toLowerCase()) ? "" : "hidden"} w-full text-md font-semibold text-left border-b-[1px] pl-2 pr-2 p-1 border-gray-300`}>
                                {formalLotNames[name]}
                            </button>
                        ))}
                    </div>
                </div>
            )}


            {/* 
                // 0 = show graph
                // 1 = error
                // 2 = no cluster selected 
                // 3 = loading
            */}

            <div className={`${displayType !== 0 && "hidden"}`}>
                <ChargerSplide
                    chargersToShowSetter={chargersToShowSetter}
                    chargersToShow={chargersToShow}
                    clustersToShow={clustersToShow}
                    simpleChargers={simpleChargers}
                    title={title}
                />
            </div>
            <div ref={graphRef} className={`${displayType === 0 ? "h-[320px]" : "hidden"} w-full mt-1`}>
                {title === "Historic Energy Consumption" ? (
                    <HistoryGraph
                        graphArray={historyGraphData}
                        graphDimensions={graphDimensions}
                        title={title}
                        chargersToShow={chargersToShow}
                        startingDateHistoryGraph={startingDateHistoryGraph}
                        endingDateHistoryGraph={endingDateHistoryGraph}
                    />
                ) : (
                    <LineGraphs
                        graphArray={combinedNoDuplicatesArray}
                        graphDimensions={graphDimensions}
                        title={title}
                        chargersToShow={chargersToShow}
                    />
                )}
            </div>
            {displayType === 1 && (
                <div className="w-full h-[360px] mt-3">
                    <div className="h-full w-full bg-red-50 bg-opacity-70 border-[1px] border-red-500 rounded-md shadow-sm p-3">
                        <h2 className="text-md font-semibold text-red-700">Error loading data</h2>
                        <h2 className="text-sm text-red-700">{error}</h2>
                    </div>
                </div>
            )}
            {displayType === 2 && (
                <div className="w-full h-[360px] mt-3">
                    <div className="h-full w-full bg-blue-50 bg-opacity-70 border-[1px] border-blue-500 rounded-md shadow-sm p-3">
                        <h2 className="text-md font-semibold">No clusters selected</h2>
                        <h2 className="text-sm">Press the select chargers dropdown to select a cluster.</h2>
                    </div>
                </div>
            )}
            {displayType === 3 && (
                <div className="w-full h-[360px] mt-3">
                    <div className="h-full w-full bg-blue-50 bg-opacity-70 border-[1px] border-blue-500 rounded-md shadow-sm p-3">
                        <h2 className="text-md font-semibold">Loading...</h2>
                        {/* <h2 className="text-sm">Press the select chargers dropdown to select a cluster.</h2> */}
                    </div>
                </div>
            )}
        </div>
    )
}

export default GraphIntermediate;