import { createSlice } from '@reduxjs/toolkit'

export const loadPercentSlice = createSlice({
    name: 'loadPercent',
    initialState: {loadPer: 0, finished: false},
    reducers: {
        changePercent: (state, action) => {
            state.loadPer = action.payload.loadPer
        },
        toggleFinished: (state, action) => {
            state.finished = !state.finished;
        }
    }
});

export const { changePercent, toggleFinished } = loadPercentSlice.actions;

export default loadPercentSlice.reducer