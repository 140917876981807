
module.exports = {
    newPolicyZeroArrays: {
        Sunday: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        Monday: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        Tuesday: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        Wednesday: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        Thursday: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        Friday: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        Saturday: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
    },

    updatedMasterObjectSetters: {
        'Mon': "Monday",
        'Tue': "Tuesday",
        'Wed': "Wednesday",
        'Thu': "Thursday",
        'Fri': "Friday",
        'Sat': "Saturday",
        'Sun': "Sunday"
    },

    indexToDayObject: {
        0: 'Sun',
        1: 'Mon',
        2: 'Tue', 
        3: 'Wed',
        4: "Thu",
        5: 'Fri',
        6: 'Sat'
    },

    newPolicyFormEnergy: [{
        energy: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        green: [],
        member: 0,
        weekday: 'Every Day'
    }],

    newPolicyFormParking: [{
        parking: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        pFee: 'Per Hour',
        member: 0,
        weekday: 'Every Day'
    }],

    newPolicyFormSleeping: [{
        sType: "time",
        sleeping: 0,
        member: 0,
        grace: 0,
        weekday: 'Every Day'
    }]


}