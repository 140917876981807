import { createPortal } from 'react-dom'
import React, { useState } from 'react';
import Settings from './settings';

function Header() {

    const [showSettings, setShowSettings] = useState(false);
    const [animationType, setAnimationType] = useState("in")
    const [showBlackBackground, setShowBlackBackground] = useState(false)
    const [showSearch, setShowSearch] = useState(false);

    const animationClass = {
        "in": 'slideIn',
        "out": 'slideOut'
    }

    function animateSettings() {
        if (!showSettings) {
            setShowBlackBackground(true)
            setShowSettings(true)
            setAnimationType("in")
        } else {
            setShowBlackBackground(false)
            setAnimationType("out")
            setTimeout(() => {
                setShowSettings(false)
            }, 150)
        }
    }

    return (
        <div className="h-14 w-full bg-white border-b-[0px] border-gray-400 dark:bg-[#2A2A2A] shadow-md dark:border-b-[1px] dark:border-[#555555] pl-4 z-50 flex justify-between items-center">
            {/* <h1 className="text-xl font-bold text-black dark:text-textWhite w-56 font-sans">{title}</h1> */}
            {/* <input type='text' placeholder='Search for anything' className='w-96 text-sm mr-8 bg-gray-100 h-8 dark:bg-sidebarDark dark:border-[#555555] rounded-full border-[1px] border-gray-500 p-2' onChange={() => setShowSearch(true)}>

            </input> */}
            <h1 className='w-96 text-lg font-semi-bold'>IntelliCharge</h1>

            <div className='w-56 flex justify-end mr-4'>
                <div className='flex items-center '>
                    <button onClick={() => animateSettings(!showSettings)} className='w-9 h-9 border-[1px] border-gray-500 bg-gray-200 rounded-full flex items-center justify-center p-1 cursor-pointer'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                        </svg>
                    </button>
                    {/* <div className={`${allPartnerData?.data?.requests_from?.length > 0 ? "block" : "hidden"} w-2 h-2 bg-red-600 rounded-full fixed -mt-[26px] ml-[18px]`}></div> */}
                </div>
            </div>
            {showSettings && (
                createPortal(
                    <>
                        <div className={`right-0 top-0 bottom-0 left-0 bg-black opacity-30 fixed z-[200] ${showBlackBackground ? "block" : "hidden"}`}>

                        </div>
                        <div className={`w-[600px] md:w-[750px] fixed z-[9999] right-0 top-0 bottom-0 animatedWidth ${animationClass[animationType]}`}>
                            <div className={`w-full h-full bg-white dark:lightBaseGray settingsShadow`}>
                                <svg onClick={() => animateSettings(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="fixed w-10 h-10 bg-white text-black cursor-pointer p-2 -ml-12 mt-2 rounded-full hover:rotate-45 duration-150">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>
                                <Settings />
                            </div>
                        </div>
                    </>
                    , document.body)
            )}
            {showSearch && (
                createPortal(
                    <>
                        <div className={`right-0 top-14 bottom-0 left-16 flex justify-center items-center bg-black bg-opacity-30 fixed z-[200] `}>
                            <div className={`fixed z-[9999] w-[600px] h-96 mb-24 bg-white animatedWidth rounded-lg`}>
                                <div className={`w-full h-full bg-white dark:lightBaseGray settingsShadow rounded-lg`}>
                                    <div className='flex justify-between h-8 items-center border-b-[1px] border-gray-400 p-2'>
                                        <h2 className='font-semibold'>Search Results</h2>
                                        <svg onClick={() => setShowSearch(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 bg-white text-black cursor-pointer">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    , document.body)
            )}
        </div>
    )
}

export default Header;