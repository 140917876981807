import { useState, useEffect, Fragment } from "react"
import { userPassUpdate } from "../../../../helpers/apis"
import DatePicker from "react-datepicker"
import { formatDatePicker } from "../../../../helpers/timeFunctions"

function Pending({
    requests,
    type,
    refreshData
}) {

    const [openedIds, setOpenedIds] = useState(-1)
    const [date, setDate] = useState("")
    const [status, setStatus] = useState("pending")

    // This function adds or removes an id from the openedIds array
    function addOrRemoveId(id) {
        setOpenedIds(prev => {
            if (prev === id) {
                return -1
            } else {
                return id
            }
        });
    }

    // This is the function that formats the date, and sends the PUT request
    async function saveUpdatedRequest(id) {
        let newDate = ""
        let localStatus = status.toLowerCase()
        if (date !== "") {
            if(date.length !== 19) {
                newDate = formatDatePicker(date)
            } else {
                newDate = date
            }
        }
        if (status === "pending") {
            localStatus = null
        }
        try {
            const updatedRequest = await userPassUpdate(id, newDate, localStatus)
            if (updatedRequest !== false) {
                refreshData()
            }
        } catch (error) {
            console.error("Error updating request in AcceptedRequests file", error)
        }
    }

    // This just ensures the status state is always correct
    useEffect(() => {
        type === "pending" && setStatus("pending")
        type === "accepted" && setStatus("accepted")
        type === "denied" && setStatus("denied")
    }, [type])

    // This sets the date state so it's always in the UI, unless it's null
    useEffect(() => {
        if (openedIds === -1 || requests.length === 0) return;
        requests.forEach((request) => {
            if (request.request_id !== openedIds) return
            if (request.request_id === openedIds && request.expiration_date) {
                if (request.expiration_date === "0000-00-00 00:00:00") return
                setDate(request.expiration_date);
            }
        });
    }, [openedIds, requests]);

    return (
        <Fragment>
            {type === "pending" && (
                <h3 className="text-sm text-yellow-600 p-[6px] border-t-[1px] border-gray-300 ">Pending:</h3>
            )}
            {type === "accepted" && (
                <h3 className="text-sm text-green-600 p-[6px] border-t-[1px] border-gray-300 ">Accepted:</h3>
            )}
            {type === "denied" && (
                <h3 className="text-sm text-red-600 p-[6px] border-t-[1px] border-gray-300">Denied:</h3>
            )}
            {requests.map((request, index) => (
                <div key={index} className={`${openedIds === request.request_id ? "" : ""} w-full p-[6px] border-t-[1px] border-gray-300`}>
                    <div className="flex w-full justify-between items-center h-5">
                        <h2 className="text-sm"><span className="mr-2">{request.username},</span> {request.response}</h2>
                        {openedIds === request.request_id ? (
                            <svg onClick={() => addOrRemoveId(request.request_id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12" />
                            </svg>
                        ) : (
                            <svg onClick={() => addOrRemoveId(request.request_id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0-3.75-3.75M17.25 21 21 17.25" />
                            </svg>
                        )}
                    </div>
                    {openedIds === request.request_id && (
                        <div className="mt-2 flex w-full items-center justify-between">
                            <div className="flex items-center">
                                <div>
                                    <h4 className="text-xs text-gray-700 mb-[3px]">Expiration Date:</h4>
                                    <DatePicker
                                        selected={date}
                                        onChange={(date) => setDate(date)}
                                        dateFormat="yyyy-MM-dd"
                                        className="bg-white h-6 w-24 pl-1 pr-1 text-sm border-[1px] rounded-full border-gray-400"
                                    />
                                </div>
                                <div className="ml-4">
                                    <h4 className="text-xs text-gray-700 mb-[3px]">Status:</h4>
                                    <select value={status} onChange={(e) => setStatus(e.target.value)} className="h-6 w-24 text-sm pl-1 pr-1 bg-white border-[1px] border-gray-400 rounded-full">
                                        <option>accepted</option>
                                        <option>denied</option>
                                        <option>pending</option>
                                    </select>
                                </div>
                            </div>
                            <button onClick={() => saveUpdatedRequest(request.request_id)} className="mt-4 border-[1px] border-primary rounded-md pl-2 pr-2 text-sm font-semibold h-6 bg-primaryAccent bg-opacity-25 text-primary">Save</button>
                        </div>
                    )}
                </div>
            ))}
        </Fragment>
    )
}

export default Pending