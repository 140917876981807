
function NoAccess() {
    return(

        <div className="h-[calc(100vh-56px)] w-full flex justify-center items-center overflow-x-hidden overflow-y-hidden">
            <div className="w-[600px] h-[400px] bg-white rounded-lg shadow-lg p-4 justify-center items-center flex">
                <h2 className="text-xl font-semibold">Sorry you do not have access to view this page.</h2>
                {/* <h3></h3> */}
            </div>
        </div>
    )
}

export default NoAccess