
import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { debounce } from 'lodash';
import { exportDataArrayToExcelReceipts } from "../../helpers/downloadToExcel";

import Receipt from "./receipt"

function NewContainer({
    charger,
    receipts,
    index,
    chargerNameIdPair
}) {

    const [filtersToShow, setFiltersToShow] = useState([])

    const [allUsers, setAllUsers] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [userSearchResults, setUserSearchResults] = useState([])

    // This useEffect simplifies the receipts data so I can use that data
    // in the filter 
    useEffect(() => {
        if (!receipts || !receipts.data) return
        try {
            const uniqueNames = [...new Set(receipts?.data[charger].map(item => item.user))];
            setAllUsers(uniqueNames)
            changeUserSearchResults("")
            // setSelectedUsers(uniqueNames)
            setTimeout(() => {
                setUserSearchResults(uniqueNames)
            }, 500)
        } catch (error) {
            console.error("Error parsing and mapping data for filter", error)
        }
    }, [receipts])

    // This enables users to search for the user they want in the filter
    const changeUserSearchResults = debounce((string) => {
        const searchString = string.toLowerCase()
        const filteredResults = allUsers.filter(
            user => user.toLowerCase().includes(searchString.toLowerCase())
        )
        setUserSearchResults(filteredResults)
    }, 200)

    console.log(receipts)
    console.log(charger)

    // This simple function adds or removes the receipt column index 
    // which determines whether or not to show the filter for that column
    function addOrRemoveFilterIndex(index) {
        setFiltersToShow(prev => {
            if (prev.includes(index)) {
                return prev.filter(existingIndex => existingIndex !== index);
            } else {
                return [...prev, index];
            }
        });
    }

    // This function handles adding or removing a user from the selectedUsers
    // state. This is what enables the users filter to work
    function addOrRemoveSelectedUser(user) {
        setSelectedUsers(prev => {
            if (prev.includes(user)) {
                return prev.filter(existinguser => existinguser !== user);
            } else {
                return [...prev, user];
            }
        });
    }

    return (
        <div key={index} className="lg:col-span-4 xl:col-span-3 h-full overflow-y-hidden  w-full relative">
            {/* {charger} */}
            <div className="w-full flex justify-between items-center p-2 h-10 bg-white shadow-md z-30">
                <h2 className="font-semibold">{chargerNameIdPair[charger]}</h2>
                <div className="flex items-center">

                    <button onClick={() => addOrRemoveFilterIndex(index)} >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                        </svg>
                    </button>
                    <button onClick={() => exportDataArrayToExcelReceipts(receipts?.data[charger], chargerNameIdPair[charger])}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 ml-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>
                    </button>
                </div>
            </div>
            {filtersToShow.includes(index) && (
                <div className="absolute z-50 w-full rounded-md bg-blue-50 shadow-xl mt-3 p-3 border-[1px] border-gray-400">
                    <h2 className="text-sm text-gray-700">Filter by users</h2>
                    <input onChange={(e) => changeUserSearchResults(e.target.value)} type="text" placeholder="Search for user" className="pl-2 pr-2 text-sm w-56 h-7 border-[1px] border-gray-400 rounded-full"></input>
                    <div className="w-full mt-1 border-[1px] border-gray-400 max-h-56 overflow-y-scroll overflow-x-hidden rounded-lg">
                        {userSearchResults.map((user, index) => (
                            <button onClick={() => addOrRemoveSelectedUser(user)} key={index} className={`${selectedUsers.includes(user) ? "bg-blue-100 border-[1px] border-secondaryAccent " : "bg-white"} w-full truncate text-left p-1 text-sm pl-2`}>
                                {user}
                            </button>
                        ))}
                    </div>
                </div>
            )}
            <div className="h-[calc(100%-40px)] w-full overflow-y-scroll overflow-x-hidden dashboardScrollBar p-2 z-20">
                {receipts?.data[charger].map((receipt, index) => {
                    if (selectedUsers.includes(receipt.user) || selectedUsers.length === 0) {
                        return (
                            <Receipt
                                key={index}
                                receipt={receipt}
                            />
                        )
                    }
                })}
            </div>
        </div>

    )
}

export default NewContainer