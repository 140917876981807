import React, { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import '@splidejs/splide/dist/css/splide.min.css';
import {useEffect, useState, useRef, Fragment } from "react";
import strings from "../../../helpers/strings";

function ChargerSplide({
    chargersToShowSetter,
    chargersToShow,
    clustersToShow,
    simpleChargers,
    title
}) {

    const [splideOptions, setSplideOptions] = useState({
        rewind: false,
        width: 900,
        height: 30,
        gap: ".1rem",
        perPage: 3,
        perMove: 1,
        type: 'slide',
        pagination: false
    })

    const splideRef = useRef()
    const goNext = () => splideRef.current.splide.go("+");
    const goPrev = () => splideRef.current.splide.go("-");

    function addOrRemoveSelectedCharger(id) {
        chargersToShowSetter(prev => {
            if (prev.includes(id)) {
                localStorage.setItem(`${id}-${title}`, false)
                return prev.filter(existingGroup => existingGroup !== id);
            } else {
                localStorage.setItem(`${id}-${title}`, true)
                return [...prev, id];
            }
        });
    }

    // This function checks to see what chargers the user was last viewing on this
    // browser, allowing them to pickup where they left off
    useEffect(() => {
        if(!title) return
        if(!simpleChargers || simpleChargers.length === 0) return
        simpleChargers.forEach((charger) => {
            let inLocalStorage = JSON.parse(localStorage.getItem(`${charger.id}-${title}`))
            if(inLocalStorage) {
                // console.log(`${charger.id}-${title}`, inLocalStorage)
                chargersToShowSetter(prev => [...prev, charger.id])
            }
        })
    }, [title, simpleChargers])

    return (
        <div className="h-8 mt-4 pl-3 pr-3 flex justify-between items-center -mr-3 -ml-3 grid grid-cols-12">
            <button className="flex items-center  justify-center col-span-1" onClick={() => goPrev()}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 dark:text-textWhite">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                </svg>
            </button>
            <div className="col-span-10">
                <Splide
                    ref={splideRef}
                    options={splideOptions}
                    hasTrack={false}
                >
                    <SplideTrack>
                        {simpleChargers?.map((data, index) => (
                            <Fragment key={index}>
                                {clustersToShow.includes(data.lot) && (
                                    <SplideSlide key={index}>
                                        <div key={index} className="flex z-50 items-center w-full h-full pl-1 pr-1 p-1">
                                            <button onClick={() => addOrRemoveSelectedCharger(index)} className={`bg-gray-100 z-[100] border-[1px] border-gray-300 cursor-pointer flex items-center h-full w-full pl-1 pr-1 rounded-[4px]`}>
                                                <div className={`${chargersToShow?.includes(index) ? `${strings.RGBGraphColorsHex[index]}` : "bg-gray-100"} h-[14px] w-[14px] rounded-lg`}></div>
                                                <h2 className="text-xs text-gray-800 font-semibold w-full truncate">{data.name}</h2>
                                            </button>
                                        </div>
                                    </SplideSlide>
                                )}
                            </Fragment>
                        ))}
                    </SplideTrack>
                    <div className="splide__arrows hidden"></div>
                </Splide>
            </div>
            <button className="flex items-center col-span-1 justify-center" onClick={() => goNext()}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 dark:text-textWhite">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
            </button>
        </div>
    )
}

export default ChargerSplide;