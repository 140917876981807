
function Receipt({
    receipt
}) {

    // console.log(receipt)

    function formatToHundredths(numberString) {
        const number = parseFloat(numberString);
        return number.toFixed(2);
    }

    return (
        <div className="w-full bg-white mb-3 shadow-sm rounded-md p-3">
            <div className="flex items-center justify-between">
            <h2 className="text-md font-semibold">{receipt.user}</h2>
            {receipt.paid === "1" ? (
                <h2 className="font-semibold text-sm text-green-600">Paid</h2>
            ) : (
                <h2 className="font-semibold text-sm text-red-600">Not Paid</h2>
            )}
            </div>
            <h2 className="text-sm">{receipt.end_time.slice(0, 10)}</h2>
            <div className="flex items-center justify-between mt-2">
                <h3 className="text-sm text-gray-700">Parking:</h3>
                <h3 className="text-sm">{formatToHundredths(receipt.parking)}</h3>
            </div>
            <div className="flex items-center justify-between mt-0">
                <h3 className="text-sm text-gray-700">Energy:</h3>
                <h3 className="text-sm">{formatToHundredths(receipt.price)}</h3>
            </div>
            <div className="flex items-center justify-between mt-0">
                <h3 className="text-md text-gray-700">Total:</h3>
                <h3 className="text-sm font-semibold">{formatToHundredths(Number(formatToHundredths(receipt.price)) + Number(formatToHundredths(receipt.parking)))}</h3>
            </div>
        </div>
    )
}

export default Receipt