
module.exports = {

    RGBGraphColors: {
        0: 'rgb(64,99,37,1)',
        1: 'rgb(132,221,99,1)',
        2: 'rgb(100,103,222,1)'
    },

    RGBGraphColorsHex: {
        0: 'bg-[#406325]',
        1: 'bg-[#84DD63]',
        2: 'bg-[#6467DE]'
    },

    // GraphColorsBG: {
    //     0: 'bg-[#406325]',
    //     1: 'bg-[#84DD63]',
    //     2: 'bg-[#6467DE]'
    // },

    GraphColorsBG: {
        0: 'bg-[#406325]', // Dark Green
        1: 'bg-[#4A732B]', // Green
        2: 'bg-[#548531]', // Green
        3: 'bg-[#5E9737]', // Green
        4: 'bg-[#68A93D]', // Green
        5: 'bg-[#72BB43]', // Light Green transitioning to Blue
        6: 'bg-[#7C9BC3]', // Blue
        7: 'bg-[#8594DD]', // Blue
        8: 'bg-[#8E8DE7]', // Light Blue transitioning to Purple
        9: 'bg-[#9776F1]', // Purple
      },

    PolicyFormTimes: ["12 AM", "1 AM", "2 AM", "3 AM", "4 AM", "5 AM", "6 AM", "7 AM", "8 AM", "9 AM", "10 AM", "11 AM", 
    "12 PM", "1 PM", "2 PM", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM", "8 PM", "9 PM", "10 PM", "11 PM"],

    CalenderInputDays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],

    allPossibleLocksPolicyForm: [
        "Sunday0", "Sunday1", "Sunday2", "Sunday3", "Sunday4", "Sunday5", "Sunday6", "Sunday7", "Sunday8", "Sunday9", "Sunday10", "Sunday11", "Sunday12", "Sunday13", "Sunday14", "Sunday15", "Sunday16", "Sunday17", "Sunday18", "Sunday19", "Sunday20", "Sunday21", "Sunday22", "Sunday23",
        "Monday0", "Monday1", "Monday2", "Monday3", "Monday4", "Monday5", "Monday6", "Monday7", "Monday8", "Monday9", "Monday10", "Monday11", "Monday12", "Monday13", "Monday14", "Monday15", "Monday16", "Monday17", "Monday18", "Monday19", "Monday20", "Monday21", "Monday22", "Monday23",
        "Tuesday0", "Tuesday1", "Tuesday2", "Tuesday3", "Tuesday4", "Tuesday5", "Tuesday6", "Tuesday7", "Tuesday8", "Tuesday9", "Tuesday10", "Tuesday11", "Tuesday12", "Tuesday13", "Tuesday14", "Tuesday15", "Tuesday16", "Tuesday17", "Tuesday18", "Tuesday19", "Tuesday20", "Tuesday21", "Tuesday22", "Tuesday23",
        "Wednesday0", "Wednesday1", "Wednesday2", "Wednesday3", "Wednesday4", "Wednesday5", "Wednesday6", "Wednesday7", "Wednesday8", "Wednesday9", "Wednesday10", "Wednesday11", "Wednesday12", "Wednesday13", "Wednesday14", "Wednesday15", "Wednesday16", "Wednesday17", "Wednesday18", "Wednesday19", "Wednesday20", "Wednesday21", "Wednesday22", "Wednesday23",
        "Thursday0", "Thursday1", "Thursday2", "Thursday3", "Thursday4", "Thursday5", "Thursday6", "Thursday7", "Thursday8", "Thursday9", "Thursday10", "Thursday11", "Thursday12", "Thursday13", "Thursday14", "Thursday15", "Thursday16", "Thursday17", "Thursday18", "Thursday19", "Thursday20", "Thursday21", "Thursday22", "Thursday23",
        "Friday0", "Friday1", "Friday2", "Friday3", "Friday4", "Friday5", "Friday6", "Friday7", "Friday8", "Friday9", "Friday10", "Friday11", "Friday12", "Friday13", "Friday14", "Friday15", "Friday16", "Friday17", "Friday18", "Friday19", "Friday20", "Friday21", "Friday22", "Friday23",
        "Saturday0", "Saturday1", "Saturday2", "Saturday3", "Saturday4", "Saturday5", "Saturday6", "Saturday7", "Saturday8", "Saturday9", "Saturday10", "Saturday11", "Saturday12", "Saturday13", "Saturday14", "Saturday15", "Saturday16", "Saturday17", "Saturday18", "Saturday19", "Saturday20", "Saturday21", "Saturday22", "Saturday23"
    ],

    abbreviatedDayNames: {
        "Sunday": "Sun",
        "Monday": "Mon",
        "Tuesday": "Tue",
        "Wednesday": "Wed",
        "Thursday": "Thu",
        "Friday": "Fri",
        "Saturday": "Sat"
    },

    indexToDay: {
        0: "Sunday",
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday"
    },

    dayToIndex: {
        "Sunday": 0,
        "Monday": 1,
        "Tuesday": 2,
        "Wednesday": 3,
        "Thursday": 4,
        "Friday": 5,
        "Saturday": 6
    }
    


}