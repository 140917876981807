import { useEffect, useState } from "react"
import { deletePass, deletePolicy } from "../../helpers/apis";
import SelectedPass from "./formComponents/selectedPass";

function ConfirmDelete({
    setShowConfirmDelete,
    chargers,
    passes,
    policies,
    title,
    name,
    refreshData
}) {

    const [affectedChargers, setAffectedChargers] = useState([])
    const [affectedPasses, setAffectedPasses] = useState([])
    const [affectedPassesNewPolicy, setAffectedPassesNewPolicy] = useState([])
    const [selectedPassesID, setSelectedPassesID] = useState([])

    const [error, setError] = useState("")

    useEffect(() => {
        const policyArray = affectedPasses.map(pass => pass.policy)
        setAffectedPassesNewPolicy(policyArray)
    }, [affectedPasses])

    // This function finds out which passes would be affected
    // if the user deleted a policy
    useEffect(() => {
        if (title !== "Delete Policy") return;
        passes.forEach((pass) => {
            if (pass.policy.includes(name) && !affectedPasses.some(affectedPass => affectedPass.id === pass.id)) {
                setAffectedPasses(prev => [...prev, pass]);
            }
        });
    }, [passes, policies, name])

    // This function finds out which chargers would be affected
    // if the user deleted a pass
    useEffect(() => {
        if (title !== "Delete Pass") return;
        chargers.forEach((charger) => {
            if (charger.passes.includes(name) && !affectedChargers.some(affectedCharger => affectedCharger.id === charger.id)) {
                setAffectedChargers(prev => [...prev, charger]);
            }
        });
    }, [chargers, name, title, affectedChargers]);

    // This function deletes a pass from the database, and handles
    // any errors
    async function deletePassFromChargers() {
        try {
            const deletedPass = await deletePass(name)
            if (!deletedPass) return
            refreshData()
            setShowConfirmDelete(false)
        } catch (err) {
            console.error("Error deleting pass", err)
        }
    }

    // This is the function that deletes the policy from the database,
    // and handles any user errors
    async function deletePolicyHandler() {
        if (affectedPassesNewPolicy.includes(name)) {
            const index = affectedPassesNewPolicy.indexOf(name);
            setError(`Error: The pass ${affectedPasses[index].pass_name} still uses the ${name} policy`);
            return;
        }
        try {
            const deletedPolicy = await deletePolicy(name)
            if (!deletedPolicy) return
            console.log(deletedPolicy)
            refreshData()
            setShowConfirmDelete(false)
        } catch (err) {
            console.error("Error deleting policy", err)
        }
    }

    function dummyFillerRefreshFunction() { }

    return (
        <div className="w-[900px] h-[600px] bg-white shadow-lg rounded-lg p-3">
            <div className="flex items-center justify-between">
                <h2 className="text-lg font-semibold">{title}, <span className="font-regular text-sm italic">{name}</span></h2>
                <button onClick={() => setShowConfirmDelete(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div className="w-full h-[504px] mt-2 border-[1px] border-gray-300">
                {title === "Delete Pass" && (
                    <>
                        <div className="w-full h-10 bg-yellow-100 border-[1px] border-yellow-700 flex items-center p-2 ">
                            <h2 className="text-yellow-700 text-sm">Warning: This pass will be removed from these chargers.</h2>
                        </div>
                        <div className="w-full h-[462px] bg-white grid grid-cols-4 gap-2 p-2 overflow-y-scroll dashboardScrollBar">
                            {affectedChargers.map((charger, index) => (
                                <div key={index} className="col-span-1 h-20 rounded-md bg-white border-[1px] border-gray-300 p-2 pt-1">
                                    <h2 className="font-semibold text-md">{charger.name}</h2>
                                    <h2 className="text-sm text-gray-700 mt-1">Base Policy:</h2>
                                    <h3 className="-mt-[3px]">{charger.policy}</h3>
                                </div>
                            ))}
                        </div>
                    </>
                )}
                {title === "Delete Policy" && (
                    <>
                        {error === "" ? (
                            <div className="w-full h-10 bg-yellow-100 border-[1px] border-yellow-700 flex items-center p-2 ">
                                <h2 className="text-yellow-700 text-sm">Warning: This pass will be removed from these chargers.</h2>
                            </div>
                        ) : (
                            <div className="w-full h-10 bg-red-100 border-[1px] border-red-700 flex items-center p-2 ">
                                <h2 className="text-red-700 text-sm">{error}</h2>
                            </div>
                        )}
                        <div className="w-full h-[462px] bg-white grid grid-cols-4 gap-2 p-2 overflow-y-scroll dashboardScrollBar">
                            {affectedPasses.map((pass, index) => (
                                <div key={index} className="col-span-1">
                                    <div className={`${affectedPassesNewPolicy[index] === name ? "bg-yellow-100" : "bg-green-100"} rounded-md border-[1px] border-gray-300 p-2 pt-1`}>
                                        <SelectedPass
                                            setSelectedPassesID={setSelectedPassesID}
                                            selectedPass={pass}
                                            policies={policies}
                                            chargers={chargers}
                                            refreshData={dummyFillerRefreshFunction}
                                            index={index}
                                            setAffectedPassesNewPolicy={setAffectedPassesNewPolicy}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
            <div className="h-12 w-full flex justify-end items-center">
                {title === "Delete Pass" && (
                    <button onClick={deletePassFromChargers} className="text-red-800 text-sm font-semibold bg-red-200 pl-2 pr-2 p-1 rounded-md border-red-800 border-[1px]">Delete</button>
                )}
                {title === "Delete Policy" && (
                    <button onClick={deletePolicyHandler} className="text-red-800 text-sm font-semibold bg-red-200 pl-2 pr-2 p-1 rounded-md border-red-800 border-[1px]">Delete</button>
                )}
            </div>
        </div>
    )
}

export default ConfirmDelete