import { createSelector } from '@reduxjs/toolkit';

const chargersIds = state => state.chargersIds;
const loadState = state => state.loading
const coordsState = state => state.coords

export const getChargerIds = createSelector(
    [chargersIds],
    (ids) => ids
)

export const getLoadPercent = createSelector(
    [loadState],
    (load) => {
      return {
        loadPer: load.loadPer,
        finished: load.finished
      }
    }
  )

export const getCoords = createSelector(
  [coordsState],
  (coords) => {
    return {
      latitude: coords.latitude,
      longitude: coords.longitude
    }
  }
)