import axios from "axios"
import Cookies from 'js-cookie'
import { jwtDecode } from "jwt-decode";
// https://gmsapi1communication.com:443/
const token = Cookies.get("jwt_token_intellicharge");

export async function fetchChargerData() {
  const chargerID = Cookies.get('charger_ID')
  try {
    const response = await axios.get(
      `https://gmsapi1communication.com:443/dashData?chargerID=${chargerID}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        },
      }
    );
    // console.log(response.data)
    return response.data;
  } catch (err) {
    console.error("Error fetching users sensors: " + err);
    throw err;
  }
}

export async function fetchUserPolicyNames() {

  const username = Cookies.get('intellicharge_username')
  try {
    const response = await axios.get(
      `https://gmsapi1communication.com:443/allpolicies?facility=${username}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        },
      }
    );
    // console.log(response.data)
    return response.data;
  } catch (err) {
    console.error("Error fetching users sensors: " + err);
    throw err;
  }
}

export async function fetchPolicyDataByName(policy) {
  const username = Cookies.get('intellicharge_username')
  try {
    const response = await axios.get(
      `https://gmsapi1communication.com:443/facilitypolicy?facility=${username}&policy=${policy}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error("Error fetching users sensors: " + err);
    throw err;
  }
}

export async function fetchPolicyUserRelations(idArray) {
  let stringArray = '[' + idArray.join(', ') + ']'
  try {
    const response = await axios.get(
      `https://gmsapi1communication.com:443/chargerpolicy?chargerID=${stringArray}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        },
      }
    );
    console.log(response.data)
    return response.data;
  } catch (err) {
    console.error("Error fetching users sensors: " + err);
    throw err;
  }
}

export async function changeChargerPolicyPut(chargerID, policy) {
  try {
    const response = await axios.put(
      `https://gmsapi1communication.com:443/chargerpolicy`,
      {
        chargerID: chargerID,
        policy: policy
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error("Error fetching users sensors: " + err);
    throw err;
  }
}

export async function deleteChargerPolicy(policy) {
  const username = Cookies.get('intellicharge_username');
  try {
    const response = await axios.delete(
      `https://gmsapi1communication.com:443/facilitypolicy`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        },
        data: {
          facility: username,
          policy: policy
        }
      }
    );
    return response.data;
  } catch (err) {
    console.error("Error in deleteChargerPolicy: " + err);
    throw err;
  }
}

export async function postNewChargerPolicy(dataP) {
  const username = Cookies.get('intellicharge_username');
  dataP.facility = username
  // console.log(dataP)
  try {
    const response = await axios.post('https://gmsapi1communication.com:443/facilitypolicy',
      dataP,
      {
        headers: {  
          "Content-Type": "application/json",
          "x-access-token": token
        }
      })
    const data = await response.data
    console.log(data)
    return data
  } catch (err) {
    console.error(err)
  }
}

export async function fetchAllUsers(idArray) {
  try {
    const response = await axios.get(
      // names should be this but stringify ['1289731829318', '123412341234', '123412341234']
      // Numbers are the ids of the chargers 
      `https://gmsapi1communication.com:443/facilityreceipts?chargerID=${idArray}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        },
      }
    );
    console.log(response)
    return response.data;
  } catch (err) {
    console.error("Error fetching all users: " + err);
    throw err;
  }
}

export async function chargerDataFetcher (url) {
  // const token = Cookies.get("jwt_token_intellicharge");
  return axios.get(url, {
    headers: { "Content-Type": "application/json", "x-access-token": `${token}` }
  }).then(res => res.data);
}

export async function fetchChargerRequestEnergyHistory(chargerId, startDate, endDate) {
  // start and end date are in this format: 2023-05-23
  // chargerId is in this format: 23182318238123
  try {
    const response = await axios.get(
      `https://gmsapi1communication.com:443/histquery?chargerID=${chargerId}&date=${startDate}&todate=${endDate}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error("Error fetching all users: " + err);
    throw err;
  }
}

export async function fetch90DayHistoryChargers(startDate, endDate) {
  const chargerID = Cookies.get('charger_ID')
  try {
    const response = await axios.get(
      `https://gmsapi1communication.com:443/historic90data?fromDate=${startDate}&toDate=${endDate}&chargerID=${chargerID}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error("Error fetching variable history api " + err);
    throw err;
  }
}

export async function fetchChargerPolicyPassesInfo() {
  const facility = Cookies.get('intellicharge_username');
  try {
    const response = await axios.get(
      `https://gmsapi1communication.com:443/chargerpolicy?facility=${facility}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error("Error fetching all users: " + err);
    throw err;
  }
}

export async function changePassesPolicyPut(name, policy) {
  const facility = Cookies.get('intellicharge_username');
  try {
    const response = await axios.put(
      `https://gmsapi1communication.com:443/facilitypass`,
      {
        facility: facility,
        name: name,
        policy: policy
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error("Error fetching users sensors: " + err);
    return false
  }
}

export async function assignPassToCharger(pass, chargerID) {
  try {
    const response = await axios.post('https://gmsapi1communication.com:443/chargerpass',
      {
        name: pass,
        chargerID: chargerID
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        }
      })
    const data = await response.data
    return data
  } catch (err) {
    console.error(err)
  }
}

export async function removePassFromCharger(pass, chargerID) {
  try {
    const response = await axios.delete(
      `https://gmsapi1communication.com:443/chargerpass`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        },
        data: {
          name: pass,
          chargerID: chargerID
        }
      }
    );
    return response.data;
  } catch (err) {
    console.error("Error in deleteChargerPolicy: " + err);
    return false
  }
}

export async function deletePass(pass) {
  const facility = Cookies.get('intellicharge_username');
  try {
    const response = await axios.delete(
      `https://gmsapi1communication.com:443/facilitypass`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        },
        data: {
          name: pass,
          facility: facility
        }
      }
    );
    return response.data;
  } catch (err) {
    console.error("Error deleting pass in API " + err);
    return false
  }
}

export async function createNewPass(pass, policy, prompt) {
  const facility = Cookies.get('intellicharge_username');
  try {
    const response = await axios.post('https://gmsapi1communication.com:443/facilitypass',
      {
        name: pass,
        facility: facility,
        policy: policy,
        prompt: prompt
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        }
      })
    const data = await response.data
    return data
  } catch (err) {
    console.error(err)
    return err
  }
}

export async function deletePolicy(policy) {
  const facility = Cookies.get('intellicharge_username');
  // console.log(policy)
  // return
  try {
    const response = await axios.delete(
      `https://gmsapi1communication.com:443/facilitypolicy`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        },
        data: {
          facility: facility,
          policy: policy
        }
      }
    );
    return response.data;
  } catch (err) {
    console.error("Error in deleteChargerPolicy: " + err);
    return false
  }
}

export async function userPassUpdate(id, expiration_date, status) {
  const facility = Cookies.get('intellicharge_username');
  try {
    const response = await axios.put(
      `https://gmsapi1communication.com:443/userpass?facility=${facility}`,
      {
        request_id: id,
        expiration_date: expiration_date,
        status: status
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error("Error fetching users sensors: " + err);
    return false
  }
}

export async function updateChargerPolicy(dataP) {
  const username = Cookies.get('intellicharge_username');
  dataP.facility = username;
  
  try {
    const response = await axios.put('https://gmsapi1communication.com:443/facilitypolicy',
      dataP,
      {
        headers: {  
          "Content-Type": "application/json",
          "x-access-token": token
        }
      });
    const data = await response.data;
    console.log(data);
    return data;
  } catch (err) {
    console.error(err);
  }
}

export async function getAllChargerUsers() {
  const chargerID = Cookies.get('charger_ID')
  try {
    const response = await axios.get(
      `https://gmsapi1communication.com:443/facilityreceipts?chargerID=${chargerID}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error("Error fetching all users: " + err);
    throw err;
  }
}

export async function getChargerReceipts(selectedChargers, fromDateData, toDateData, users) {
  try {
    const response = await axios.get(
      `https://gmsapi1communication.com:443/receipts?chargerID=${JSON.stringify(selectedChargers)}&date=${fromDateData}&todate=${toDateData}&users=${JSON.stringify([users])}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error("Error fetching all users: " + err);
    throw err;
  }
}