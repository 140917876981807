
import React, { useRef, useState } from "react";
import axios from 'axios';
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { setChargerIds } from "../redux/reducers/chargersSlice";

function Login() {

    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const dispatch = useDispatch()

    const loginUsernameRef = useRef()
    const loginPasswordRef = useRef()

    function loginUser(event) {
        event.preventDefault()
        const verify = verifyCriteria()
        if (verify === false) {
            return;
        }
        let username = loginUsernameRef.current.value
        let password = loginPasswordRef.current.value
        const loginBody = {
            "username": username,
            "password": password
        }
        fetchUserLogin(loginBody, username, password)
    }

    function verifyCriteria() {
        if (!loginUsernameRef.current.value) {
            setErrorMessage("Error! Email or username required.")
            setError(true)
            return false;
        }
        if (!loginPasswordRef.current.value) {
            setErrorMessage("Error! Invalid Password.")
            setError(true)
            return false;
        }
    }

    async function fetchUserLogin(loginBody, username, password) {
        if (username !== 'commissioner'){
            try {

                // const response = await axios.post(`http://52.201.123.158:8080/das/user/login`, loginBody)
                const response = await axios.post(`https://gmsapi1communication.com:443/facilityusercheck`, loginBody)
                console.log(response)
                const token = await response.data
                if (!token) {
                    setError(true)
                    setErrorMessage("Invalid login credentials. Is your password correct?")
                    return
                }
                if (token.status === "bad") {
                    setError(true)
                    setErrorMessage("Status: Bad")
                    return
                }
                Cookies.set('intellicharge_page_access', [response.data.component_access], { secure: true })
                Cookies.set('jwt_token_intellicharge', token.token, { secure: true, sameSite: 'Strict' });
                Cookies.set('intellicharge_username', username, { secure: true});
                Cookies.set('intellicharge_password', password, { secure: true, sameSite: 'Strict'});
                Cookies.set('charger_ID', token.chargerID, { secure: true});
                dispatch(setChargerIds(token.chargerID))
                window.location = "/dashboard"
            } catch (err) {
                setError(true)
                setErrorMessage("Invalid login credentials. Is your password correct?")
                console.error("axios POST failed during login" + err)
            }
        }
        else {
            try {
                const response = await axios.get(`https://gmsapi1communication.com:443/commission/login?username=${username}&password=${password}`)
                console.log(response)
                const token = await response.data
                Cookies.set('jwt_token_intellicharge', token.token, { secure: true, sameSite: 'Strict' });
                window.location = "/commissioner"
            }
            catch (err) {
                setError(true)
                setErrorMessage("Invalid login credentials. Is your password correct?")
                console.error("axios POST failed during login" + err)
            }
        }
    }

    return (
        <div className="w-100 h-screen bg-cover bg-center bg-gray-700 flex">
            <div className="w-96 min-w-96 h-100 bg-baseWhite p-6 pr-10 pl-10">
                <div className="flex align-middle mt-6">
                    <img className="h-8" src="/Grid_logo_mark.png"></img>
                    <h2 className="h-10 text-2xl font-semibold">IntelliCharge</h2>
                </div>
                <h1 className="mt-8 text-3xl font-normal">Facility Login </h1>
                
                <div className={`${error === true ? "block" : "hidden"} mt-2 p-1 w-full bg-red-300 border-red-600 border-[2px]`}>
                    <h2 className="text-md text-red-800">{errorMessage}</h2>
                </div>
                <form onSubmit={loginUser}>
                    <div className=" mt-3">
                        <input ref={loginUsernameRef} name="username" placeholder="Email or Username" className="h-10 w-full text-lg p-1 border-black border-[1px] rounded-md" type="text"></input>
                        <input ref={loginPasswordRef} name="current-password" autoComplete="current-password" placeholder="Password" className="h-10 w-full text-lg p-1 border-black border-[1px] rounded-md mt-2" type="password"></input>
                    </div>
                    {/* <div>
                        <a className="text-sm text-blue-600 cursor-pointer">
                            Forgot Password?
                        </a>
                    </div>
                    <div>
                        <a className="text-sm text-blue-600 cursor-pointer">
                            Forgot Email or Username?
                        </a>
                    </div> */}
                    {/* <div className="flex align-middle items-center mt-2">
                        <input type="checkbox" className="w-5 h-5"></input>
                        <h3 className="h-5 text-md mb-1 ml-1">Remember me</h3>
                    </div> */}
                    {/* <Link to="/dashboard"> */}
                    <button type="submit" className="w-full h-10 bg-blue-700 mt-2 rounded-md text-white text-lg font-bold shadow-md">
                        Log In
                    </button>
                    {/* </Link> */}
                </form>
                {/* <div className="w-full pl-14 pr-14">
                    <button className="w-full h-8 font-semibold text-lg text-blue-700">
                        Sign Up
                    </button>
                </div> */}
            </div>
            <div className="h-full p-12 pl-16">
                <div className="">
                    <h1 className="text-white font-bold text-4xl">Bring intelligence to EV Charging</h1>
                    <p className="text-white mt-2 text-lg leading-snug">IntelliCharge is an end-to-end charging solution, including EV charging
                        <br></br> hardware, a mobile app, a charging management platform, and a 
                        <br></br>cloud-based control center.</p>
                    <a href="https://www.gridelevated.com/" className="text-white mt-4 font-semibold underline leading-normal cursor-pointer">Learn More</a>
                </div>
            </div>
        </div>
    )
}

export default Login;