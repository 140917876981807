import React, { useEffect, useState } from "react"
import { changePassesPolicyPut } from "../../../helpers/apis"
import { createPortal } from "react-dom"

import ConfirmDelete from "../confirmDelete"

function SelectedPass({
    setSelectedPassesID,
    selectedPass,
    policies,
    chargers,
    refreshData,
    setAffectedPassesNewPolicy,
    index
}) {

    const [selectedPolicy, setSelectedPolicy] = useState("")
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [loadingPut, setLoadingPut] = useState(false)
    const [savedPut, setSavedPut] = useState(false)
    // const [showNotif, setShowNotif] = useState(true)

    useEffect(() => {
        if (!selectedPass || !selectedPass.policy) return
        setSelectedPolicy(selectedPass.policy)
    }, [selectedPass])

    // This function closes the selectedPass
    function removePassID(id) {
        setSelectedPassesID(prev => {
            if (prev.includes(id)) {
                return prev.filter(existingId => existingId !== id);
            }
        });
    }

    // This function calls the PUT API to handle the pass changing 
    // policies, and sets the new state for the immediate UI feedback
    async function changePolicyAndState(e) {
        setSavedPut(false)
        setLoadingPut(true)
        setAffectedPassesNewPolicy(prev => {
            let newArray = [...prev]
            newArray[index] = policy
            return newArray
        })
        const policy = e.target.value
        setSelectedPolicy(policy)
        try {
            const updatedPass = await changePassesPolicyPut(selectedPass.pass_name, policy)
            console.log(updatedPass)
            if(updatedPass) {
                setLoadingPut(false)
                setSavedPut(true)
                setTimeout(() => {
                    setSavedPut(false)
                }, 10000)
            }
            refreshData()
        } catch (err) {
            setLoadingPut(false)
            console.error("Error switching policies for a pass", err)
        }
    }



    return (
        <>
            <div className="w-full h-full rounded-lg p-2">
                <div className="flex justify-between">
                    <h2 className="font-semibold">{selectedPass.pass_name}</h2>
                    <div className="flex items-center">
                        <svg onClick={() => setShowConfirmDelete(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 mr-[2px] cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                        </svg>
                        <svg onClick={() => removePassID(selectedPass.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                <h2 className="text-sm text-gray-700 mt-0">Current Policy:</h2>
                <select value={selectedPolicy} onChange={(e) => changePolicyAndState(e)} className="smaller-bold-shadow rounded-full border-[1px] p-[2px] pl-2 pr-2 border-gray-400">
                    {policies.map((policy, index) => (
                        <option key={index}>{policy.policy}</option>
                    ))}
                </select>
                {loadingPut && (
                    <div className="flex items-center mt-1 justify-end">
                        <h2 className="text-sm italic text-gray-600 ml-1 mb-[1px]">saving...</h2>
                    </div>
                )}
                {savedPut && (
                    <div className="flex items-center mt-1 justify-end">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-green-600">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
                        </svg>
                        <h2 className="text-sm italic text-green-600 ml-1 mb-[1px]">saved</h2>
                    </div>
                )}

            </div>
            {showConfirmDelete && (
                createPortal(
                    <div className={`fixed z-[9999] right-0 left-0 top-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center`}>
                        <ConfirmDelete
                            setShowConfirmDelete={setShowConfirmDelete}
                            chargers={chargers}
                            selectedPass={selectedPass}
                            title={"Delete Pass"}
                            name={selectedPass.pass_name}
                            refreshData={refreshData}
                        />
                    </div>
                    , document.body
                )
            )}
        </>
    )
}

export default SelectedPass