module.exports = {
  convertHoursToDateForLineGraph(time) {
    const baseDate = new Date(); // Use today's date as the base
    baseDate.setHours(0, 0, 0, 0); // Reset to midnight to start the day

    const hours = Math.floor(time);
    const minutes = Math.floor((time % 1) * 60);

    baseDate.setHours(baseDate.getHours() + hours);
    baseDate.setMinutes(baseDate.getMinutes() + minutes);

    return baseDate;
  },

  formatDatePicker(date) {
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString().split('T')[0] + 'T00:00:00';
  },

  getFormattedDateHistory(daysAgo) {
    const date = new Date();
    date.setDate(date.getDate() - daysAgo);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
},

parseCustomDateHistory(timeStr) {
  const timePart = timeStr.split('-')[0];
  return new Date(timePart.replace(/\//g, '-'));
},


convertDecimalToTime(decimalTime) {
  // Extract the integer part (hours) and the fractional part (minutes)
  const hours = Math.floor(decimalTime);
  const minutes = Math.round((decimalTime - hours) * 60);

  // Determine AM or PM
  const period = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  const standardHours = hours % 12 || 12;

  // Format minutes to always be two digits
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

  // Construct the standard time string
  return `${standardHours}:${formattedMinutes} ${period}`;
}


};
