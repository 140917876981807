
function StatusSummaries({allChargerStatuses}) {
    return(
        <div className="col-span-12 flex">
        <div className="p-1 pt-[1px] pb-[1px] mr-3 flex items-center rounded-lg border-[1px] border-green-700 bg-green-50">
            <h2 className="text-sm italic text-green-700">Charging:</h2>
            <h2 className="ml-2 text-md text-green-700">{allChargerStatuses?.charging}</h2>
        </div>
        <div className="p-2 pt-[1px] pb-[1px] mr-3 flex items-center rounded-lg border-[1px] border-blue-700 bg-blue-50">
            <h2 className=" text-sm italic text-blue-700">Sleeping:</h2>
            <h2 className="ml-2 text-md text-blue-700">{allChargerStatuses?.connected + allChargerStatuses?.sleeping}</h2>
        </div>
        <div className="p-2 pt-[1px] pb-[1px] mr-3 flex items-center rounded-lg border-[1px] border-gray-700 bg-gray-50">
            <h2 className=" text-sm italic text-gray-700">Not Connected:</h2>
            <h2 className="ml-2 text-md text-gray-700">{allChargerStatuses?.not_connected}</h2>
        </div>
        <div className="p-2 pt-[1px] pb-[1px] mr-3 flex items-center rounded-lg border-[1px] border-red-700 bg-red-50">
            <h2 className=" text-sm italic text-red-700">Offline:</h2>
            <h2 className="ml-2 text-md text-red-700">{allChargerStatuses?.offline}</h2>
        </div>
    </div>
    )
}

export default StatusSummaries