
module.exports = {

    // This function removes duplicate entries from each 
    // charger power array. (If they share the same time)
    removeDuplicates(array) {
        let tempTime = 0
        let tempArray = []
        array.forEach((object) => {
            if (tempTime === object.time) {
                return
            } else {
                tempTime = object.time
                tempArray.push(object)
            }
        })
        // console.log(tempArray)
        return tempArray
    },

    // This function performs a couple of roles.
    // 1. It creates multiple new arrays in this format: [[], [], []], based on lots.
    // 2. It sets the first charger of each lot to visible: true, so that at least one
    //    charger is represented on the graph for each lot.
    groupByLot(chargerArray) {
        const groupedData = chargerArray.reduce((accumulator, current) => {
            const group = accumulator.find(group => group[0].lot === current.lot);
            if (group) {
                group.push({ ...current, visible: false });
            } else {
                accumulator.push([{ ...current, visible: true }]);
            }
            return accumulator;
        }, []);
        return groupedData;
    },

    // This is the function that calculates the cumulative KWh for the dashboard graph
    // calculateCumulativeKWh(datasets) {
    //     return datasets.map(dataset => {
    //         let cumulativeKWh = 0;
    //         let previousTime = 0
    //         // console.log(dataset)
    //         dataset.power = dataset.power.map((point, index) => {
    //             // console.log(point)
    //             if (index > 0) {
    //                 const timeDifference = point.time - previousTime;
    //                 const energy = parseFloat(dataset.power[index - 1].value) * timeDifference;
    //                 cumulativeKWh += energy;
    //             }
    //             previousTime = point.time;
    //             let tempValue = Number(cumulativeKWh.toFixed(2));
    //             let accountForVolts = ((tempValue * 208) / 1000);
    //             return {
    //                 time: point.time,
    //                 value: accountForVolts
    //             };
    //         });
    //         return dataset;
    //     });
    // },

    calculateCumulativeKWh(dataset) {
        let cumulativeKWh = 0;
        let previousTime = 0
        return dataset.map((point, index) => {
            if (index > 0) {
                const timeDifference = point.time - previousTime;
                const energy = parseFloat(dataset[index - 1].value) * timeDifference;
                cumulativeKWh += energy;
            }
            previousTime = point.time;
            let tempValue = Number(cumulativeKWh.toFixed(2));
            let accountForVolts = ((tempValue * 208) / 1000);
            return {
                time: point.time,
                value: accountForVolts
            }
        });
    },

    // This function calculates the volts for the dashboard graph
    calculateVolts(dataset) {
        return dataset.map(item => {
            return {
                time: item.time,
                value: String((parseFloat(item.value) * 208 / 1000).toFixed(3))
            };
        });
    },

    getMinMaxTimes(dataArray) {
        // Flatten the array
        const flatArray = dataArray.flat().filter(item => item && item.time);

        // Extract time values and convert them to Date objects
        const times = flatArray.map(item => new Date(item.time.replace(/-/g, '/')));

        // Find the minimum and maximum dates
        const minTime = new Date(Math.min(...times));
        const maxTime = new Date(Math.max(...times));

        // Format dates for Chart.js (ISO 8601 format)
        const formatForChartJS = (date) => date.toISOString();

        return {
            minTime: formatForChartJS(minTime),
            maxTime: formatForChartJS(maxTime)
        };
    },

    // formatForHistoryGraph(data) {
    //     const result = [];
    //     for (const id in data) {
    //         if (data.hasOwnProperty(id)) {
    //             const formattedArray = data[id].map(entry => {
    //                 if (entry.energy && entry.time) {
    //                     // Split the time to separate the date
    //                     const [date, _] = entry.time.split('-');
    //                     // Format the energy value
    //                     const energyValue = parseFloat(entry.energy).toFixed(2)
    //                     return { time: date, value: energyValue };
    //                 }
    //                 return null;
    //             })

    //             result.push(formattedArray);
    //         }
    //     }
    //     return result;
    // }

    formatForHistoryGraph(data) {
        const keys = Object.keys(data.data);
        let newArray = []
        for (let keyIndex = 0; keyIndex < keys.length; keyIndex++) {
            let restructureData = data.data[keys[keyIndex]]
                .filter(item => item.time) // Filter out items that don't have a time value
                .map(item => {
                    const date = item.time.split('-')[0];
                    const value = parseFloat(item.energy).toFixed(2);
                    return {
                        time: date,
                        value: parseFloat(value)
                    };
                });
            newArray.push(restructureData);
        }
        
        
        const noDupTimes = newArray.map(subArray => {
            const timeMap = {};
    
            subArray.forEach(obj => {
                if (timeMap[obj.time]) {
                    timeMap[obj.time] += obj.value;
                } else {
                    timeMap[obj.time] = obj.value;
                }
            });
    
            return Object.keys(timeMap).map(time => ({
                time: time,
                value: Number(timeMap[time].toFixed(2))
            }));
        });
        return noDupTimes
    }
}