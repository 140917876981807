import { Link } from "react-router-dom";
import React, { useState } from "react";
import SidebarButton from "./sidebarButton";
import Cookies from 'js-cookie';

function Sidebar() {
    const [sidebarWidth, setSidebarWidth] = useState(false);
    const [settings, setSettings] = useState(false);
    const [activeIcon, setActiveIcon] = useState("dashboard");

    return (
        <>
            <div
                className={`${sidebarWidth === false && settings === false ? "w-16" : "w-56"
                    } h-[100vh] fixed bg-secondary border-r-[0px] border-gray-400 dark:bg-lightestBaseGray z-50 duration-100 ease-in-out`}
                onMouseOver={() => {
                    setSidebarWidth(true);
                }}
                onMouseLeave={() => {
                    setSidebarWidth(false);
                }}
            >
                <div className={`${sidebarWidth === false && settings === false ? "w-16" : "w-16"} duration-100 ease-in-out h-14 p-3 flex items-center border-r-[0px] border-b-[0px] border-gray-400 dark:bg-lightestBaseGray`}>
                    <img src="/Grid_logo_mark.png" />
                </div>
                <div
                    className={`${sidebarWidth === false && settings === false ? "w-16" : "w-56"
                        } h-72 mt-8 duration-150 ease-in-out`}
                >
                    <Link
                        to="/dashboard"

                        className={`flex hover:bg-IntellichargeGray duration-75 ${sidebarWidth === false && settings === false ? "w-16" : "w-56"
                            } ${activeIcon === "dashboard" ? "bg-transparent" : "bg-transparent"}`}
                    >
                        <SidebarButton settings={settings} sidebarWidth={sidebarWidth} title={"Dashboard"} svgPath={"m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"} />
                    </Link>
                    <Link
                        to="/payments"

                        className={`flex mt-1 hover:bg-IntellichargeGray duration-75 ${sidebarWidth === false && settings === false ? "w-16" : "w-56"
                            } ${activeIcon === "data" ? "bg-blue-500" : "bg-transparent"}`}
                    >
                        <SidebarButton settings={settings} sidebarWidth={sidebarWidth} title={"Payments"} svgPath={"M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"} />
                    </Link>
                    <Link
                        to="/history"

                        className={`flex mt-1 hover:bg-IntellichargeGray duration-75 ${sidebarWidth === false && settings === false ? "w-16" : "w-56"
                            } ${activeIcon === "data" ? "bg-blue-500" : "bg-transparent"}`}
                    >
                        <SidebarButton settings={settings} sidebarWidth={sidebarWidth} title={"History"} svgPath={"M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"} />
                    </Link>

                    {/* <Link
                        to="/market"

                        className={`flex mt-1 hover:bg-IntellichargeGray duration-75 ${sidebarWidth === false && settings === false ? "w-16" : "w-56"
                            } ${activeIcon === "market" ? "bg-blue-500" : "bg-transparent"}`}
                    >
                        <SidebarButton settings={settings} sidebarWidth={sidebarWidth} title={"Market"} svgPath={"M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"}/>
                    </Link> */}
                    <Link to="/" onClick={() => Cookies.remove('jwt_token_intellicharge')} className={`${sidebarWidth === false && settings === false ? "w-16" : "w-56"
                        } fixed flex bottom-4 hover:bg-IntellichargeGray duration-75`}>
                        <SidebarButton settings={settings} sidebarWidth={sidebarWidth} title={"Logout"} svgPath={"M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"}/>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default Sidebar;
