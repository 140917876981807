
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie"
import { getAllChargerUsers, fetchChargerData, getChargerReceipts } from "../helpers/apis";
import { debounce } from 'lodash';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import NoAccess from "../globalComponents/noAccess";

import Header from "../globalComponents/header";
import ReceiptsContainer from "./historyComponents/receiptsContainer";
import Receipt from "./historyComponents/receipt";
import NewContainer from "./historyComponents/newContainer";
import HistorySplide from "./historyComponents/historySplide";

function History() {

    const [allUsers, setAllUsers] = useState([])
    const [userSearchResults, setUserSearchResults] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectedChargers, setSelectedChargers] = useState([])
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [chargerIds, setChargerIDs] = useState([])
    const [chargerNames, setChargerNames] = useState([])
    const [chargerNameIdPair, setChargerNameIdPair] = useState({})
    const [receipts, setReceipts] = useState([])

    const [fakePer, setFakePer] = useState(0)
    const [loadingForm, setLoadingForm] = useState(true)
    const [loadingData, setLoadingData] = useState(true)
    const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false)
    const [error, setError] = useState("")

    const chargerID = Cookies.get('charger_ID')
    const page_access = Cookies.get('intellicharge_page_access')

    const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : '';
    const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : '';

    // console.log(selectedChargers)
    // console.log(chargerNameIdPair)

    // This useEffect fetches the users associated with the chargers
    useEffect(() => {
        if (!chargerID) return
        try {
            const fetchData = async () => {
                try {
                    // This fetches the charger data and names 
                    const chargerData = await fetchChargerData()
                    const names = Object.values(chargerData.names);
                    const ids = Object.keys(chargerData.names);
                    setChargerNameIdPair(chargerData.names)
                    setChargerNames(names);
                    setChargerIDs(ids);
                    // This fetches the users and sets the user search results
                    const users = await getAllChargerUsers();
                    setAllUsers(users.data)
                    setUserSearchResults(users.data)
                    setLoadingForm(false)
                } catch (error) {
                    console.error('Error fetching charger users:', error);
                }
            };
            fetchData();
        } catch (error) {
            console.error("Error fetching users related to chargers.", error)
        }
    }, [chargerID])

    // This async function handles the form submission, and 
    // deals with all error handling issues. 
    async function submitForm() {
        setError("")
        if (selectedChargers.length === 0) {
            setError("Error, please select at least one charger.")
            return
        }
        if (formattedStartDate === '') {
            setError("Error, invalid start date.")
            return
        }
        if (formattedEndDate === '') {
            setError("Error, invalid end date.")
            return
        }
        setFakePer(0)
        const intervalId = setInterval(() => {
            const randomAddition = Math.floor(Math.random() * 4) + 1;
            setFakePer(prevFakePer => {
                let newFakePer = prevFakePer + randomAddition;
                if (newFakePer >= 94) {
                    clearInterval(intervalId)
                    return 95
                }
                return newFakePer;
            });
        }, 50);
        setFormHasBeenSubmitted(true)
        setLoadingData(true)
        try {
            const receipts = await getChargerReceipts(selectedChargers, formattedStartDate, formattedEndDate, selectedUsers)
            console.log(receipts)
            clearInterval(intervalId);
            setFakePer(100)
            setTimeout(() => {
                setReceipts(receipts)
                setLoadingData(false)
            }, 300)
        } catch (error) {
            console.error("There was an error fetching the receipts on history page", error)
        }
    }

    // This debounce function changes what users are show
    // for the operator to select
    const changeUserSearchResults = debounce((string) => {
        const searchString = string.toLowerCase()
        const filteredResults = allUsers.filter(
            user => user.toLowerCase().includes(searchString.toLowerCase())
        )
        setUserSearchResults(filteredResults)
    }, 200)

    // This function adds or removes a selected user 
    function addOrRemoveUser(user) {
        setSelectedUsers(prev => {
            if (prev.includes(user)) {
                return prev.filter(existingUser => existingUser !== user);
            } else {
                return [...prev, user];
            }
        });
    }

    // This function adds or removes a selected user 
    function addOrRemoveCharger(id) {
        setSelectedChargers(prev => {
            if (prev.includes(id)) {
                return prev.filter(existingID => existingID !== id);
            } else {
                return [...prev, id];
            }
        });
    }

    return (
        <>
            <Header title={"Historic Data"} />
            {!page_access.includes('history') ? (
                <NoAccess/>
            ) : ( 
            <div className="h-[calc(100vh-56px)] w-full overflow-x-hidden overflow-y-hidden flex">
                <div className="h-full bg-white overflow-y-scroll dashboardScrollBar border-t-[1px] border-gray-300 p-4 w-[330px]">
                    <h1 className="text-xl font-bold text-black dark:text-white w-56 font-sans">Transaction History</h1>
                    {loadingForm ? (
                        <div className="w-full h-96 bg-yellow-50 mt-2 p-2 border-[1px] border-gray-400 rounded-lg">
                            <h2 className="font-semibold">Loading...</h2>
                        </div>
                    ) : (
                        <div className=" rounded-lg mt-2 min-h-72">
                            <div className="w-72">
                                <h2 className="text-sm">Select Chargers:</h2>
                                {error === "Error, please select at least one charger." && (
                                    <div className="p-1 bg-red-100 border-red-600 border-[1px] mb-1">
                                        <h3 className="text-sm text-red-600">{error}</h3>
                                    </div>
                                )}
                                <div className="overflow-y-scroll w-72 max-h-64 policyFormScrollBar rounded-lg border-[1px] border-gray-400">
                                    {chargerNames.map((name, index) => (
                                        <button
                                            className={`${selectedChargers.includes(chargerIds[index]) ? "bg-blue-100 border-[1px] border-secondaryAccent" : "bg-white"} w-full text-left text-sm pl-2 p-1`}
                                            onClick={() => addOrRemoveCharger(chargerIds[index])}
                                            key={index}
                                        >
                                            {name}
                                        </button>
                                    ))}
                                </div>
                                {error === "Error, invalid start date." && (
                                    <div className="p-1 bg-red-100 border-red-600 border-[1px] mt-2">
                                        <h3 className="text-sm text-red-600">{error}</h3>
                                    </div>
                                )}
                                {error === "Error, invalid end date." && (
                                    <div className="p-1 bg-red-100 border-red-600 border-[1px] mt-2">
                                        <h3 className="text-sm text-red-600">{error}</h3>
                                    </div>
                                )}
                                <div className="flex mt-2">
                                    <div className="">
                                        <h2 className="text-sm">From:</h2>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            dateFormat="yyyy-MM-dd"
                                            className="bg-white h-7 w-24 pl-2 pr-2 text-sm border-[1px] z-[200] z-1000 rounded-full border-gray-400"
                                            popperPlacement="bottom-end"
                                        />
                                    </div>
                                    <div className="ml-2">
                                        <h2 className="text-sm">To:</h2>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            dateFormat="yyyy-MM-dd"
                                            className="bg-white h-7 w-24 pl-2 pr-2 text-sm border-[1px] rounded-full border-gray-400"
                                        />
                                    </div>
                                </div>
                                <h2 className="text-sm mt-2">Select Users: <span className="ml-1 text-xs text-gray-600">optional</span></h2>
                                <input type="text" placeholder="Search for a user" className={`text-sm pl-2 pr-2 h-7 rounded-full border-[1px] border-gray-400`} onChange={(e) => changeUserSearchResults(e.target.value)}></input>
                                <div className="overflow-y-scroll w-72 h-64 policyFormScrollBar mt-2 border-[1px] border-gray-400 rounded-lg">
                                    {userSearchResults.map((result, index) => (
                                        <button className={`${selectedUsers.includes(result) ? "bg-blue-100 border-[1px] border-secondaryAccent" : "bg-white"} w-full text-left text-sm pl-2 p-1`} onClick={() => addOrRemoveUser(result)} key={index}>{result}</button>
                                    ))}
                                </div>
                                <div className="flex justify-end mt-2">
                                    <button onClick={() => submitForm()} className="pl-2 pr-2 p-1 bg-primary text-sm font-semibold text-white rounded-md">Submit</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="h-full w-[calc(100vw-390px)] overflow-y-scroll dashboardScrollBar">
                    <div className="p-4 pl-2 pr-2 w-full h-full">
                        {receipts.length > 0 ? (
                            <>
                                {/* {receipts.map((receipt, index) => (
                                    <Receipt
                                        key={index}
                                        receipt={receipt}
                                    />
                                ))} */}
                            </>
                        ) : (
                            <>
                                {formHasBeenSubmitted ? (
                                    <>
                                        {loadingData ? (
                                            <div className="w-full h-full flex justify-center items-center">
                                                <div className="text-center justify-center items-center flex-col flex bg-white w-[400px] h-[400px] pt-20 rounded-lg shadow-md">
                                                    <img className='w-[150px] h-[150px] -mt-24 mb-10' src='/Grid_logo_mark.png'></img>
                                                    <h2 className="text-md mt-4 font-semibold font-sans italic">Fetching receipts... {fakePer}%</h2>
                                                    <div className="w-64 h-4 mt-3 bg-gray-200 rounded-xl">
                                                        <div style={{ width: `${fakePer}%` }} className="w-16 h-4 pulsating-background rounded-xl duration-100 ease-in-out">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {receipts?.data === "empty" ? (
                                                    <div className="w-full h-full flex items-center justify-center p-48 pl-56 pr-56">
                                                        <div className="w-full h-full bg-yellow-50 rounded-lg shadow-md p-3 justify-center items-center flex">
                                                            <h2 className="text-lg font-semibold">There are no receipts with this criteria!</h2>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    // <div className="w-full h-full grid grid-cols-12 gap-4">
                                                    //     {Object.keys(receipts.data).map((charger, index) => (
                                                    //         <NewContainer
                                                    //             key={index}
                                                    //             charger={charger}
                                                    //             receipts={receipts}
                                                    //             index={index}
                                                    //             chargerNameIdPair={chargerNameIdPair}
                                                    //         />
                                                    //     ))}
                                                    // </div>
                                                    <HistorySplide
                                                        receipts={receipts}
                                                        chargerNameIdPair={chargerNameIdPair}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <div className="w-full h-full flex items-center justify-center p-48 pl-56 pr-56">
                                        <div className="w-full h-full bg-white rounded-lg shadow-md p-3 justify-center items-center flex">
                                            <h2 className="text-lg font-semibold">Complete the form to the left!</h2>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div >

            )}
        </>
    )
}

export default History;