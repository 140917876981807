import { useState, useRef, useEffect } from "react"
import ClusterMap from "./clusterMap"

function MapContainer({ dataArray, paymentPage, mapSettings }) {

    const [mapDimensions, setMapDimensions] = useState({ height: 0, width: 0 })
    const mapParentRef = useRef()
    
    // console.log(dataArray)

    // This ensures the map is always the size it should be, and allows the map to reload 
    // when the size of the viewport is changed
    useEffect(() => {
        if (mapParentRef.current) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const parentDiv = entry.contentRect
                    let newHeight = parentDiv.height
                    let newWidth = parentDiv.width
                    setMapDimensions(dimensions => ({ ...dimensions, height: newHeight, width: newWidth }))
                    // console.log(newWidth)
                }
            })
            resizeObserver.observe(mapParentRef.current);
            return () => resizeObserver.disconnect();
        }
    }, [mapParentRef])

    return (
        <div ref={mapParentRef} className={`w-full h-full bg-white`}>
            {/* <MapBox mapDimensions={mapDimensions} paymentPage={paymentPage} mapSettings={mapSettings} chargerDataArray={dataArray} /> */}
            <ClusterMap mapDimensions={mapDimensions} paymentPage={paymentPage} mapSettings={mapSettings} chargerDataArray={dataArray} />
        </div>
    )

}

export default MapContainer;