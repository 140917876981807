
function TableKeys() {
    return(
        <div className="w-full h-10 grid grid-cols-12 border-b-[1px] border-t-[1px] border-gray-400 bg-gray-100">
        <div className="h-full col-span-2 flex items-center border-r-[1px] border-gray-400">
            <h2 className="ml-2 text-sm">Charger</h2>
        </div>
        <div className="h-full col-span-2 flex items-center border-r-[1px] border-gray-400">
            
            <h2 className="ml-2 text-sm">Status</h2>
        </div>
        {/* <div className="h-full col-span-1 flex items-center border-r-[1px] border-gray-400">
            <h2 className="ml-2 text-sm">Authorized</h2>
        </div> */}
        <div className="h-full col-span-2 flex items-center border-r-[1px] border-gray-400">
            <h2 className="ml-2 text-sm">Departure</h2>
        </div>
        <div className="h-full col-span-3 flex items-center border-r-[1px] border-gray-400">
            <h2 className="ml-2 text-sm">User</h2>
        </div>
    </div>
    )
}

export default TableKeys