import { useState, useEffect } from "react";
import { getPricesForEachDay } from "../../../../helpers/payment";
import { PolicyFormTimes, allPossibleLocksPolicyForm, CalenderInputDays, dayToIndex, indexToDay, abbreviatedDayNames } from "../../../../helpers/strings";

function EnergyInput({
    energyArray,
    setUpdatedEnergyArray
}) {

    const [selectedDayData, setSelectedDayData] = useState(Array(24).fill(0))
    const [selectedDay, setSelectedDay] = useState("")
    const [lockedDateTime, setLockedDateTime] = useState([])
    const [energyObjects, setEnergyObjects] = useState([
        { weekday: "Sunday", energy: Array(24).fill(0) },
        { weekday: "Monday", energy: Array(24).fill(0) },
        { weekday: "Tuesday", energy: Array(24).fill(0) },
        { weekday: "Wednesday", energy: Array(24).fill(0) },
        { weekday: "Thursday", energy: Array(24).fill(0) },
        { weekday: "Friday", energy: Array(24).fill(0) },
        { weekday: "Saturday", energy: Array(24).fill(0) }
    ])

    // This function uses a helper in payment.js to get the energy array
    // for each day, allowing easier storage in state. The helper function
    // can determine which days are weekdays, weekends, etc
    useEffect(() => {
        console.log(energyArray)
        if (energyArray.length === 0 || !energyArray) {
            setTimeout(() => {
                setSelectedDay("Sunday")
                // console.log(energyObjects)
                return
            }, 300)
        }
        const sorted = getPricesForEachDay(energyArray, 'energy')
        const updatedObjects = energyObjects.map(item => ({
            ...item,
            energy: sorted[item.weekday] ? [...sorted[item.weekday]] : item.energy
        }));
        if(updatedObjects[0].energy.length === 0 && updatedObjects[1].energy.length === 0) {
            setBasePriceWithLock(0)
        } else {
            setEnergyObjects(updatedObjects)
        }
        setTimeout(() => {
            setSelectedDay("Sunday")
        }, 100)
    }, [energyArray])

    // This changes which energy array should be shown based on the selected day
    useEffect(() => {
        let temp = []
        energyObjects.forEach((object) => {
            if (object.weekday === selectedDay) {
                temp = object.energy
            }
        })
        setSelectedDayData(temp)
    }, [selectedDay, energyObjects])

    // This adds or removes the date time combo from the locked array
    function addOrRemoveDateTime(value) {
        setLockedDateTime(prev => {
            if (prev.includes(value)) {
                return prev.filter(time => time !== value);
            } else {
                return [...prev, value];
            }
        });
    }

    // This is a simple toggle to either lock or unlock every 
    // value input box
    function lockOrUnlockEverything() {
        if (lockedDateTime.length === 168) {
            setLockedDateTime([])
        } else {
            setLockedDateTime(allPossibleLocksPolicyForm)
        }
    }

    useEffect(() => {
        setUpdatedEnergyArray(energyObjects)
    }, [energyObjects])

    // This function handles changes to the input fields,
    // and changes it so the user can see immediate changes
    // and saves them in state
    function handleValueInputs(index, value) {
        let lockedLiteral = `${selectedDay}${index}`
        if (lockedDateTime.includes(lockedLiteral)) return
        if (index > -1) {
            let tempData = [...selectedDayData];
            tempData[index] = Number(value);
            setSelectedDayData(tempData);
            setEnergyObjects(prev => {
                return prev.map(object => {
                    if (object.weekday === selectedDay) {
                        return {
                            ...object,
                            energy: tempData
                        };
                    }
                    return object;
                });
            });
        }
    }

    // This function breaks apart the locked times code ('Monday12'), and then
    // keeps that day and time the same, while replacing all unlocked values
    // with the new base price
    function setBasePriceWithLock(value) {
        if (value < 0) return
        if (lockedDateTime.length === 168) return
        let num = Number(value)
        let newBaseArrays = [];
        for (const day in CalenderInputDays) {
            newBaseArrays[day] = new Array(24).fill(num);
        }
        lockedDateTime.forEach((lockedTime) => {
            const indexMatch = lockedTime.match(/(\d+)$/);
            const index = indexMatch ? parseInt(indexMatch[0], 10) : null;
            const day = indexMatch ? lockedTime.slice(0, -indexMatch[0].length) : lockedTime;
            try {
                let currentValue = energyObjects[dayToIndex[day]].energy[index]
                newBaseArrays[dayToIndex[day]][index] = currentValue
            } catch (err) {
                console.error("Weird error where energyObjects state just doesn't exist")
                console.log(index)
                console.log(energyObjects)
            }
        })
        setEnergyObjects(prev => {
            return prev.map((object, index) => {
                return {
                    ...object,
                    energy: newBaseArrays[index]
                };
            });
        });
    }

    return (
        <div className={`mt-1 w-full border-[1px] border-gray-400 rounded-lg p-2`}>
            <div className="flex items-center justify-between mt-0 mb-1 h-6">
                <div className="flex items-center">
                    <h2 className="text-sm">Repeat:</h2>
                    {/* <svg onClick={() => setShowRepeatOptions(!showRepeatOptions)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 ml-2 cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                    </svg> */}
                    {/* {showRepeatOptions && (
                            <div className={`fixed z-[9999] bg-gray-100 p-2 border-[1px] border-gray-400 rounded-lg h-10 mt-[92px] flex items-center`}>
                                <h2 className="font-semibold">{selectedDay}</h2>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 ml-1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                </svg>
                                <button onClick={() => { addOrRemoveDuplicateDay("Sun") }} className={`${selectedDay === "Sun" ? "hidden" : "block"} ${repeatDays.includes("Sun") ? "text-black font-semibold" : "text-gray-600"} ml-2 `}>Sun</button>
                                <button onClick={() => { addOrRemoveDuplicateDay("Mon") }} className={`${selectedDay === "Mon" ? "hidden" : "block"} ${repeatDays.includes("Mon") ? "text-black font-semibold" : "text-gray-600"} ml-2 `}>Mon</button>
                                <button onClick={() => { addOrRemoveDuplicateDay("Tue") }} className={`${selectedDay === "Tue" ? "hidden" : "block"} ${repeatDays.includes("Tue") ? "text-black font-semibold" : "text-gray-600"} ml-2 `}>Tue</button>
                                <button onClick={() => { addOrRemoveDuplicateDay("Wed") }} className={`${selectedDay === "Wed" ? "hidden" : "block"} ${repeatDays.includes("Wed") ? "text-black font-semibold" : "text-gray-600"} ml-2 `}>Wed</button>
                                <button onClick={() => { addOrRemoveDuplicateDay("Thu") }} className={`${selectedDay === "Thu" ? "hidden" : "block"} ${repeatDays.includes("Thu") ? "text-black font-semibold" : "text-gray-600"} ml-2 `}>Thu</button>
                                <button onClick={() => { addOrRemoveDuplicateDay("Fri") }} className={`${selectedDay === "Fri" ? "hidden" : "block"} ${repeatDays.includes("Fri") ? "text-black font-semibold" : "text-gray-600"} ml-2 `}>Fri</button>
                                <button onClick={() => { addOrRemoveDuplicateDay("Sat") }} className={`${selectedDay === "Sat" ? "hidden" : "block"} ${repeatDays.includes("Sat") ? "text-black font-semibold" : "text-gray-600"} ml-2  `}>Sat</button>
                                <svg onClick={() => setShowRepeatOptions(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-5 h-5 ml-4 mt-[1px]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>
                            </div>
                        )} */}
                </div>
                <div className="flex items-center">
                    <h2 className="text-sm ">Base Price:</h2>
                    <div className="flex items-center border-gray-400 border-[1px] rounded-[4px] h-6 ml-2 bg-gray-100">
                        <h2 className="ml-[8px]">$</h2>
                        <input onChange={(e) => setBasePriceWithLock(e.target.value)} type="text" className=" w-10 pl-1 pr-1 h-6 border-[1px] border-gray-400 ml-[3px] " ></input>
                        <h2 className="ml-[3px] pr-2">/kWh</h2>
                    </div>
                </div>
                <svg onClick={() => lockOrUnlockEverything()} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${lockedDateTime.length === 168 ? "bg-red-50 border-red-800 text-red-800" : "bg-gray-100 border-gray-400"} w-6 h-6 rounded-full border-[1px] ml-4 p-1 cursor-pointer`}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                </svg>
            </div>
            <div className="h-8 flex justify-between pl-4 pr-4 -ml-2 -mr-2 mt-2 border-b-[1px] border-t-[1px] border-gray-400 bg-gray-200">
                {CalenderInputDays.map((day, index) => (
                    <button key={index} onClick={() => setSelectedDay(day)} className={`${day === selectedDay ? "font-semibold" : ""} text-md`}>{abbreviatedDayNames[day]}</button>
                ))}
            </div>
            <div className="w-full h-56 grid grid-cols-8 mt-2 ">
                {selectedDayData?.map((time, index) => (
                    <div key={index} className="flex flex-col col-span-1 items-center justify-center w-full">
                        <svg onClick={() => addOrRemoveDateTime(`${selectedDay}${index}`)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${lockedDateTime.includes(`${selectedDay}${index}`) ? "hidden" : "block"} w-4 h-4 ml-1 cursor-pointer`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 1 1 9 0v3.75M3.75 21.75h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H3.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                        </svg>
                        <svg onClick={() => addOrRemoveDateTime(`${selectedDay}${index}`)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${lockedDateTime.includes(`${selectedDay}${index}`) ? "block" : "hidden"} text-red-600 w-4 h-4 ml-1 cursor-pointer`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                        </svg>
                        <h3 className="text-xs">{PolicyFormTimes[index]}</h3>
                        <input onChange={(e) => handleValueInputs(index, e.target.value)} type="number" value={selectedDayData[index]} className="w-10 text-sm text-center h-6 rounded-[4px] border-[1px] border-gray-400 bg-gray-100"></input>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default EnergyInput;