import { useState, useEffect } from "react";
import { getPricesForEachDay } from "../../../../helpers/payment";
import { CalenderInputDays, abbreviatedDayNames } from "../../../../helpers/strings";

function SleepingInput({
    sleepingArray,
    setUpdatedSleepingArray
}) {

    const [chargeType, setChargeType] = useState("static")
    const [selectedDay, setSelectedDay] = useState("")
    const [sleepingObjects, setSleepingObjects] = useState([
        { weekday: "Sunday", grace: 0, sType: 'time', sleeping: 0},
        { weekday: "Monday", grace: 0, sType: 'time', sleeping: 0},
        { weekday: "Tuesday", grace: 0, sType: 'time', sleeping: 0},
        { weekday: "Wednesday", grace: 0, sType: 'time', sleeping: 0},
        { weekday: "Thursday", grace: 0, sType: 'time', sleeping: 0},
        { weekday: "Friday", grace: 0, sType: 'time', sleeping: 0},
        { weekday: "Saturday", grace: 0, sType: 'time', sleeping: 0}
    ])

    // console.log(sleepingArray)

    // This breaks apart the sleeping, grace, and sType for each weekday
    useEffect(() => {
        let eachDaySleeping = getPricesForEachDay(sleepingArray, 'sleeping')
        let eachDayGrace = getPricesForEachDay(sleepingArray, 'grace')
        let eachDaySType = getPricesForEachDay(sleepingArray, 'sType')
        setSleepingObjects(prevState =>
            prevState.map(obj => ({
                ...obj,
                sleeping: eachDaySleeping[obj.weekday],
                grace: eachDayGrace[obj.weekday],
                sType: eachDaySType[obj.weekday]
            }))
        );
        setTimeout(() => {
            setSelectedDay("Sunday")
        }, 300)
    }, [sleepingArray])

    // This picks through the sleepingObjects to find if the sType is time based or not
    const findSType = (day) => {
        const dayObject = sleepingObjects.find(obj => obj.weekday === day);
        return dayObject ? dayObject.sType : null;
    };

    const findSleepingValue = (day) => {
        const dayObject = sleepingObjects.find(obj => obj.weekday === day);
        return dayObject ? dayObject.sleeping : 0;
    };

    const findGrace = (day) => {
        const dayObject = sleepingObjects.find(obj => obj.weekday === day);
        return dayObject ? dayObject.grace : 0;
    };

    // This changes the state when the user switches between Percent of Total and 
    // Static Fee
    const handleFlatChargeOptions = (e) => {
        const newChargeType = e.target.value;
        setChargeType(newChargeType);
        const newSType = newChargeType === "Percent Of Total" ? "percent" : "fee";

        setSleepingObjects(prevState =>
            prevState.map(obj =>
                obj.weekday === selectedDay ? { ...obj, sType: newSType } : obj
            )
        );
    };

    // This changes the state when the user switches between Time Based and Flat
    const handleSTypeChange = (type) => {
        setSleepingObjects(prevState =>
            prevState.map(obj =>
                obj.weekday === selectedDay ? { ...obj, sType: type } : obj
            )
        );
    };

    const handleSleepingChange = (e) => {
        const newValue = Number(e.target.value);
        setSleepingObjects(prevState =>
            prevState.map(obj =>
                obj.weekday === selectedDay ? { ...obj, sleeping: newValue} : obj
            )
        );
    };

    // This changes the grace value for each day in state
    const handleGraceChange = (e) => {
        const newValue = Number(e.target.value)
        setSleepingObjects(prevState =>
            prevState.map(obj =>
                obj.weekday === selectedDay ? { ...obj, grace: newValue } : obj
            )
        );
    };

    useEffect(() => {
        setUpdatedSleepingArray(sleepingObjects)
    }, [sleepingObjects])

    return (
        <>
            <h2 className="mt-4 text-gray-800 text-sm">Sleeping & Grace Fees:</h2>
            <div className="w-full mt-1 rounded-lg border-[1px] border-gray-400 pb-3">
                <div className={`flex items-center justify-around bg-gray-200 h-8 border-b-[1px] border-gray-400 rounded-tr-lg rounded-tl-lg`}>
                    {CalenderInputDays.map((day, index) => (
                        <button key={index} onClick={() => setSelectedDay(day)} className={`${day === selectedDay ? "font-semibold" : ""} text-md`}>{abbreviatedDayNames[day]}</button>
                    ))}
                </div>
                <div className='w-full pl-5 pr-5'>
                    {/* <h2 className="mt-2 text-gray-700 text-sm">Sleeping Charge:</h2> */}
                    <div className="flex mt-2">
                        <label className="text-sm flex items-center">
                            <input
                                type="radio"
                                className="mr-1"
                                onChange={() => handleSTypeChange('time')}
                                checked={findSType(selectedDay) === 'time'}
                            />
                            Time Based
                        </label>
                        <label className="text-sm flex items-center ml-4">
                            <input
                                type="radio"
                                className="mr-1"
                                onChange={() => handleSTypeChange('fee')}
                                checked={findSType(selectedDay) !== 'time'}
                            />
                            Flat
                        </label>
                    </div>
                    <div className="flex items-center mt-2">
                        <div className="flex items-center border-gray-400 border-[1px] rounded-[4px] h-6 ml-0 bg-gray-100">
                            {chargeType === "Percent Of Total" ? (
                                <h2 className="ml-[8px]"></h2>
                            ) : (
                                <h2 className="ml-[8px]">$</h2>
                            )}
                            <input
                                type="number"
                                value={findSleepingValue(selectedDay)}
                                onChange={handleSleepingChange}
                                className=" w-10 pl-1 pr-1 h-6 border-[1px] border-gray-400 ml-[3px] "
                            ></input>
                            {findSType(selectedDay) !== 'time' ? (
                                <div className="flex">
                                    {chargeType === "Static Fee" ? (
                                        <h2 className="ml-[3px] pr-2"></h2>
                                    ) : (
                                        <h2 className="ml-[3px] pr-2">%</h2>
                                    )}
                                    <select
                                        value={findSType(selectedDay) === 'percent' ? "Percent Of Total" : "Static Fee"}
                                        onChange={handleFlatChargeOptions}
                                        className="rounded-tr-[4px] border-l-[1px] rounded-br-[4px] bg-gray-100 h-6 border-b-[1px] border-t-[1px] border-gray-400 pb-[2px]"
                                    >
                                        <option>Static Fee</option>
                                        <option>Percent Of Total</option>
                                    </select>
                                </div>
                            ) : (
                                <h2 className="ml-[3px] pr-2">/Hour</h2>
                            )}
                        </div>
                    </div>
                    <h2 className="mt-3 text-gray-700 text-sm">Grace Period:</h2>
                    <div className="flex items-center mt-1">
                        <div className="flex items-center border-gray-400 border-[1px] rounded-[4px] h-6 ml-0 bg-gray-100">
                            <input
                                onChange={handleGraceChange} type="number"
                                value={findGrace(selectedDay)}
                                className=" w-10 pl-1 pr-1 h-6 border-[1px] border-gray-400 ml-[3px] "
                            ></input>
                            <h2 className="ml-[3px] pr-2">Hours</h2>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SleepingInput;