import { useState, useEffect } from "react"

function PassManagerQuick({
    userPasses,
    setShowUserPassInfo
}) {

    const [undecided, setUndecided] = useState(0)

    // This function loops through the user pass data and finds which 
    // requests have been accepted, denied, or undecided.
    useEffect(() => {
        if (!userPasses) return
        function sortUserPasses() {
            let denied = []
            let accepted = []
            let undecided = []
            try {
                Object.keys(userPasses.data).forEach(key => {
                    let array = userPasses.data[key]
                    array.forEach(item => {
                        if (item.status === "accepted") { accepted.push(item) }
                        if (item.status === "denied") { denied.push(item) }
                        if (item.status !== "denied" && item.status !== "accepted") { undecided.push(item) }
                    })
                })
                setUndecided(undecided.length)
            } catch (error) {
                console.error("Error sorting the user passes in quick view")
            }
        }
        sortUserPasses()
    }, [userPasses])

    return (
        <div className="w-full h-full rounded-lg">
            <h2 className="font-semibold">User Pass Manager</h2>
            <div className="flex items-center">
                <h2 className="text-3xl font-bold text-green-600 mr-2">{undecided}</h2>
                <h3 className="text-[14px]">pending requests</h3>
            </div>
            <button onClick={() => setShowUserPassInfo(true)} className="mt-[10px] text-sm font-semibold text-white border-[1px] h-[30px] mr-1 border-primary pl-2 pr-2 p-1 rounded-lg bg-primary">Open Manager</button>
        </div>
    )
}

export default PassManagerQuick