import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { useState, useEffect, useRef } from "react";
import 'chart.js/auto';
import timeFunctions from "../../../helpers/timeFunctions";
import 'chartjs-adapter-date-fns';
import strings from "../../../helpers/strings";
import { useDispatch } from "react-redux";
import { changePercent } from "../../../redux/reducers/loadingSlice";
import { getMinMaxTimes } from "../../../helpers/componentHelpers/dashboardGraphs"

function ExpandedLineGraphs({
    graphDimensions,
    graphArray,
    noDuplicatesArray,
    title,
    chargersToShow
}) {

    // console.log(title)

    const parentDivDimensions = {
        width: graphDimensions.width,
        height: graphDimensions.height,
    };

    const chartRef = useRef(null)
    const [minY, setMinY] = useState(0);
    const [maxY, setMaxY] = useState(50);
    const [chartData, setChartData] = useState({
        labels: ["0", "1"],
        datasets: [],
    })
    const [startTime, setStartTime] = useState("")
    const [endTime, setEndTime] = useState("")
    const dispatch = useDispatch()

    const currentTime = new Date()

    useEffect(() => {
        if (!graphArray) return
        if (graphArray.length === 0) return
        const datasets = graphArray.map((dataset, index) => ({
            label: `Dataset ${index + 1}`,
            data: dataset.map(point => ({
                x: timeFunctions.convertHoursToDateForLineGraph(point?.time ? point?.time : "0"),
                y: Number(point.value)
            })),
            pointRadius: 0,
            pointHitRadius: 20,
            borderWidth: 2.3,
            borderColor: strings.RGBGraphColors[index],
            fill: false,
            hidden: !chargersToShow.includes(index)
        }));
        let tempMax = -Infinity
        const visibleGraphsData = datasets.filter(item => item.hidden === false).map(item => item.data);
        const flatGraphArray = visibleGraphsData.flat()
        flatGraphArray.forEach((time) => {
            let floatValue = parseFloat(time.y)
            if (floatValue > tempMax) {
                tempMax = floatValue
            }
        })
        setMaxY(Math.floor(tempMax + (Math.floor(.3 * tempMax))) + 1)
        setChartData(prevChartData => ({
            ...prevChartData,
            datasets: datasets,
        }));
        dispatch(changePercent({ loadPer: 100 }))
    }, [graphArray, noDuplicatesArray, chargersToShow])

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.options.scales.y.max = maxY;
            chartRef.current.update();
        }
    }, [maxY])

    const [chartOptions, setChartOptions] = useState({
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                type: "linear",
                position: "left",
                beginAtZero: false,
                min: minY,
                max: maxY,
                title: {
                    display: true,
                    text: title === "Cumulative Energy Consumption" ? "kWh" : "kW",
                    font: {
                        size: 12,
                        weight: 400,
                        lineHeight: .5,
                    },
                    color: 'gray',
                    // color: '#E1DEE1',
                    padding: 6,
                },
                ticks: {
                    padding: 5
                }
            },
            x: {
                type: 'time',
                time: {
                    unit: 'minute',
                    displayFormats: {
                        minute: 'h:mm'
                    },
                    tooltipFormat: 'MMM d, h:mm a'
                },
                title: {
                    display: false,
                    text: 'Time of Day'
                },
                ticks: {
                    maxTicksLimit: 10  
                },
                min: new Date(currentTime.getTime() - 4 * 60 * 60 * 1000).toISOString(),
                max: new Date(currentTime.getTime() + 1 * 60 * 60 * 1000).toISOString()
            },
        },
        plugins: {
            legend: {
                display: false
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'xy',
                },
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'y',
                },
            },
            tooltip: {
                callbacks: {
                    title: function (context) {
                        return context[0].label;
                    },
                    label: function (context) {
                        return context.formattedValue;
                    }
                }
            },
        },
    })

    return (
        <div style={parentDivDimensions}>
            <Line ref={chartRef} data={chartData} options={chartOptions} />
        </div>
    )
}

export default ExpandedLineGraphs;