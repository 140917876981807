import { useState, useEffect } from "react"
import { debounce } from "lodash"
import { assignPassToCharger } from "../../../helpers/apis"

function AddPassModal({
    setAddPassModal,
    selectedCharger,
    passes,
    refreshData
}) {

    const [selectedPasses, setSelectedPasses] = useState([])
    const [passesSearchResults, setPassesSearchResults] = useState([])
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    // This handles the search bar input
    const changePassesSearchResults = debounce((string) => {
        const searchString = string.toLowerCase()
        const filteredResults = passes.filter(
            charger => charger.pass_name.toLowerCase().includes(searchString.toLowerCase())
        )
        setPassesSearchResults(filteredResults)
    }, 200)

    // This function adds or removes the pass from the selected passes
    // state 
    function addOrRemoveSelectedPasses(passObject) {
        setSelectedPasses(prev => {
            const isAlreadySelected = prev.some(existingPass => existingPass.pass_name === passObject.pass_name);
            if (isAlreadySelected) {
                return prev.filter(existingPass => existingPass.pass_name !== passObject.pass_name);
            } else {
                return [...prev, passObject];
            }
        });
    }

    // This sets the intial search results to include all of the user's passes
    useEffect(() => {
        if (!passes || passes.length === 0) return
        setPassesSearchResults(passes)
    }, [passes])

    // This function handles any user input errors, and then loops through each
    // selected pass and adds it to the charger's passes
    function handleSubmitButton() {
        setError("")
        if (loading) return
        if (selectedPasses.length === 0) {
            setError("Error: No passes have been selected")
            return
        }
        setLoading(true)
        selectedPasses.forEach((pass) => {
            async function postPass() {
                try {
                    if (selectedCharger.passes.includes(pass.pass_name)) {
                        setError(`Error: This charger already has ${pass.pass_name}`)
                        return
                    }
                    const postedPass = await assignPassToCharger(pass.pass_name, selectedCharger.chargerID)
                    console.log(postedPass)
                } catch (error) {
                    setError(`Error: Couldn't post this pass, ${pass.pass_name}`)
                    console.error("Error posting pass to charger", error)
                    return
                }
            }
            postPass()
        })
        if (error === "") {
            setError("success")
            setTimeout(() => {
                refreshData()
                setAddPassModal(false)
            }, 1000)
            setLoading(false)
        } 
    }

    return (
        <div className="w-[700px] h-[540px] bg-white shadow-lg rounded-lg p-3">
            <div className="flex items-center w-full justify-between">
                <h2 className="text-lg font-semibold">Add Pass, <span className="italic font-semibold text-sm">{selectedCharger.name}</span></h2>
                <button onClick={() => setAddPassModal(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            {error === "success" ? (
                <div className="w-full h-[444px] mt-2 bg-white border-[1px] border-gray-300 flex items-center justify-center">
                    <h2 className="text-green-700 font-semibold">Success! Your passes have been added.<br></br> Refetching now...</h2>
                </div>
            ) : (
                <>
                    <div className="w-full h-[444px] mt-2 border-[1px] border-gray-300 grid grid-cols-12">
                        <div className="col-span-5 h-full border-r-[1px] border-gray-300">
                            {/* <h2 className="font-semibold p-2">Search For A Pass</h2> */}
                            <div className=" p-2 border-b-[1px] border-gray-300">
                                <input onChange={(e) => changePassesSearchResults(e.target.value)} className="bg-gray-100 h-8 pl-2 w-56 pr-2 text-sm border-[1px] border-gray-400 rounded-full" placeholder="Search for a pass"></input>
                            </div>
                            <div className="h-[calc(100%-49px)] w-full bg-gray-100 overflow-y-scroll overflow-x-hidden dashboardScrollBar">
                                {passesSearchResults.map((pass, index) => (
                                    <button onClick={() => addOrRemoveSelectedPasses(pass)} className={`${selectedPasses.includes(pass) ? "border-[1px] border-secondaryAccent bg-blue-100" : "border-b-[1px] border-gray-300 bg-gray-100"} w-full text-start pl-2 pr-2 p-1 text-sm`} key={index}>
                                        {pass.pass_name}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className="col-span-7 h-full flex flex-col bg-gray-100 overflow-y-scroll overflow-x-hidden dashboardScrollBar p-2">
                            {error !== "" && (
                                <div className="w-full h-12 bg-red-100 rounded-md border-[1px] border-red-700 p-2 mb-2">
                                    <h2 className="text-red-700 text-sm">{error}</h2>
                                </div>
                            )}
                            <div className="flex flex-wrap">
                                {selectedPasses.map((pass, index) => (
                                    <div key={index} className="p-2 flex bg-white smaller-bold-shadow border mr-2 mb-2 h-[86px] items-start rounded-md">
                                        <div>
                                            <h2 className="font-semibold">{pass.pass_name}</h2>
                                            <h4 className="text-xs text-gray-700 mt-2">Policy:</h4>
                                            <h3 className="-mt-[2px]">{pass.policy}</h3>
                                        </div>
                                        <button onClick={() => addOrRemoveSelectedPasses(pass)} className="mt-[2px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className=" size-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </>
            )}
            <div className="h-8 w-full flex items-center justify-end">
                <button disabled={loading} onClick={() => handleSubmitButton()} className="text-sm w-16 h-7 font-semibold bg-primary pl-2 pr-2 p-1 text-white rounded-md mt-4 flex items-center justify-center">{loading ? <div className="lds-ring-loading"><div></div><div></div><div></div><div></div></div> : "Submit"}</button>
            </div>
        </div>
    )
}

export default AddPassModal