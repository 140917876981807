import { createSlice } from '@reduxjs/toolkit'

export const chargersSlice = createSlice({
    name: 'chargersIds',
    initialState: [],
    reducers: {
        setChargerIds: (state, action) => {
            return action.payload;
        },
        addChargerIds: (state, action) => {
            console.log(action.payload)
            state.push(action.payload);
        },
        removeChargerIds: (state, action) => {
            return state.filter(item => item !== action.payload);
        }
    }
})

export const { setChargerIds, addChargerIds, removeChargerIds } = chargersSlice.actions;

export default chargersSlice.reducer;