import { useState, useEffect } from "react";
import { PolicyFormTimes, allPossibleLocksPolicyForm, CalenderInputDays, dayToIndex } from "../../../../helpers/strings";
import { getPricesForEachDay, getFeeTypeParking } from "../../../../helpers/payment";

function ParkingInput({
    parkingInfo,
    setAlteredParkingValues
}) {


    const [selectedDayData, setSelectedDayData] = useState(Array(24).fill(0))
    const [chargePerHour, setChargePerHour] = useState(true)
    const [flatFeePrice, setFlatFeePrice] = useState(0)
    const [selectedDay, setSelectedDay] = useState("")
    const [lockedDateTime, setLockedDateTime] = useState([])
    const [parkingObjects, setParkingObjects] = useState([
        { weekday: "Sunday", pFee: 'Per Hour', parking: Array(24).fill(0) },
        { weekday: "Monday", pFee: 'Per Hour', parking: Array(24).fill(0) },
        { weekday: "Tuesday", pFee: 'Per Hour', parking: Array(24).fill(0) },
        { weekday: "Wednesday", pFee: 'Per Hour', parking: Array(24).fill(0) },
        { weekday: "Thursday", pFee: 'Per Hour', parking: Array(24).fill(0) },
        { weekday: "Friday", pFee: 'Per Hour', parking: Array(24).fill(0) },
        { weekday: "Saturday", pFee: 'Per Hour', parking: Array(24).fill(0) }
    ])

    // This useEffect alters the parkingObjects state to reflect current values
    // it calculates what the pFee for each day is, as well as the parking array
    useEffect(() => {
        if (!parkingInfo) return
        if (parkingInfo.length === 0) return
        let eachDay = getPricesForEachDay(parkingInfo, 'parking')
        // console.log(eachDay)
        let feeType = getFeeTypeParking(parkingInfo)
        if (parkingInfo.length === 0) {
            let tempFee = parkingInfo[0]?.pFee
            feeType = { Sunday: tempFee, Monday: tempFee, Tuesday: tempFee, Wednesday: tempFee, Thursday: tempFee, Friday: tempFee, Saturday: tempFee }
        }
        const updatedObjects = parkingObjects.map(item => ({
            ...item,
            pFee: feeType[item.weekday] || item.pFee,
            parking: eachDay[item.weekday] ? [...eachDay[item.weekday]] : item.parking
        }));
        setParkingObjects(updatedObjects);
        setSelectedDay("Sunday")
    }, [parkingInfo])

    // This useEffect handles when the user changes which day they'd like 
    // to view, and determines if it's Per Hour or Flat Fee, and sets the 
    // values accordingly
    useEffect(() => {
        if (selectedDay === "") return
        parkingObjects.forEach((object) => {
            if (object.weekday === selectedDay) {
                if (object.pFee === 'Per Hour') {
                    setChargePerHour(true)
                    setSelectedDayData(object.parking)
                } else {
                    setChargePerHour(false)
                    setFlatFeePrice(object.parking[0])
                }
            }
        })
        setAlteredParkingValues(parkingObjects)
    }, [selectedDay, parkingObjects])

    // This function handles changing the state when the user changes the
    // per hour parking fee inputs
    const handlePerHourValueChange = (value, index) => {
        let lockedLiteral = `${selectedDay}${index}`
        if (lockedDateTime.includes(lockedLiteral)) return
        const updatedParkingObjects = parkingObjects.map(parkingObject => {
            if (parkingObject.weekday === selectedDay) {
                const updatedParking = [...parkingObject.parking];
                updatedParking[index] = value;
                return { ...parkingObject, parking: updatedParking };
            }
            return parkingObject;
        });
        setParkingObjects(updatedParkingObjects);
    };

    // This function handles when a user changes the flat fee 
    const handleFlatFeePriceChange = (value) => {
        const updatedParkingObjects = parkingObjects.map(parkingObject => {
            if (parkingObject.weekday === selectedDay) {
                const updatedParking = Array(24).fill(value)
                return { ...parkingObject, parking: updatedParking };
            }
            return parkingObject;
        });
        setFlatFeePrice(value)
        setParkingObjects(updatedParkingObjects);
    }

    const handleChangingChargeType = (type) => {
        const updatedParkingObjects = parkingObjects.map(parkingObject => {
            if (parkingObject.weekday === selectedDay) {
                return { ...parkingObject, pFee: type };
            }
            return parkingObject;
        });
        setParkingObjects(updatedParkingObjects);
        if (type === 'Per Hour') {
            setChargePerHour(true)
        } else {
            setChargePerHour(false)
        }
    }

    // This is a simple toggle to either lock or unlock every 
    // value input box
    function lockOrUnlockEverything() {
        if (lockedDateTime.length === 168) {
            setLockedDateTime([])
        } else {
            setLockedDateTime(allPossibleLocksPolicyForm)
        }
    }

    // This adds or removes the date time combo from the locked array
    function addOrRemoveDateTime(value) {
        setLockedDateTime(prev => {
            if (prev.includes(value)) {
                return prev.filter(time => time !== value);
            } else {
                return [...prev, value];
            }
        });
    }

    // This function breaks apart the locked times code ('Monday12'), and then
    // keeps that day and time the same, while replacing all unlocked values
    // with the new base price
    function setBasePriceWithLock(value) {
        if (value < 0) return
        if (lockedDateTime.length === 168) return
        let num = Number(value)
        let newBaseArrays = [];
        for (const day in CalenderInputDays) {
            newBaseArrays[day] = new Array(24).fill(num);
        }
        lockedDateTime.forEach((lockedTime) => {
            const indexMatch = lockedTime.match(/(\d+)$/);
            console.log(lockedTime)
            const index = indexMatch ? parseInt(indexMatch[0], 10) : null;
            const day = indexMatch ? lockedTime.slice(0, -indexMatch[0].length) : lockedTime;
            try {
                let currentValue = parkingObjects[dayToIndex[day]].parking[index]
                newBaseArrays[dayToIndex[day]][index] = currentValue
            } catch (err) {
                console.error("Weird error where parkingObjects state just doesn't exist")
                console.log(index)
                console.log(parkingObjects)
            }
        })
        setParkingObjects(prev => {
            return prev.map((object, index) => {
                return {
                    ...object,
                    parking: newBaseArrays[index]
                };
            });
        });
    }

    return (
        <>

            <h2 className="mt-4 text-gray-800 text-sm">Parking Fees:</h2>
            <div className="w-full mt-1 rounded-lg border-[1px] border-gray-400 pb-3">
                <div className="flex items-center justify-between mt-0 h-10 p-2">
                    <div className="flex items-center">
                        <h2 className="text-sm">Repeat:</h2>
                    </div>
                    <div className="flex items-center">
                        <h2 className="text-sm ">Base Price:</h2>
                        <div className="flex items-center border-gray-400 border-[1px] rounded-[4px] h-6 ml-2 bg-gray-100">
                            <h2 className="ml-[8px]">$</h2>
                            <input onChange={(e) => setBasePriceWithLock(e.target.value)} type="text" className=" w-10 pl-1 pr-1 h-6 border-[1px] border-gray-400 ml-[3px] " ></input>
                            <h2 className="ml-[3px] pr-2">/Hour</h2>
                        </div>
                    </div>
                    <svg onClick={() => lockOrUnlockEverything()} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${lockedDateTime.length === 168 ? "bg-red-50 border-red-800 text-red-800" : "bg-gray-100 border-gray-400"} w-6 h-6 rounded-full border-[1px] ml-4 p-1 cursor-pointer`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                    </svg>
                </div>
                <div className={`flex items-center justify-around bg-gray-200 h-8 border-b-[1px] border-t-[1px] border-gray-400`}>
                    <button onClick={() => { setSelectedDay("Sunday") }} className={`${selectedDay === "Sunday" ? "font-semibold" : "text-gray-600"}`}>Sun</button>
                    <button onClick={() => { setSelectedDay("Monday") }} className={`${selectedDay === "Monday" ? "font-semibold" : "text-gray-600"}`}>Mon</button>
                    <button onClick={() => { setSelectedDay("Tuesday") }} className={`${selectedDay === "Tuesday" ? "font-semibold" : "text-gray-600"}`}>Tue</button>
                    <button onClick={() => { setSelectedDay("Wednesday") }} className={`${selectedDay === "Wednesday" ? "font-semibold" : "text-gray-600"}`}>Wed</button>
                    <button onClick={() => { setSelectedDay("Thursday") }} className={`${selectedDay === "Thursday" ? "font-semibold" : "text-gray-600"}`}>Thu</button>
                    <button onClick={() => { setSelectedDay("Friday") }} className={`${selectedDay === "Friday" ? "font-semibold" : "text-gray-600"}`}>Fri</button>
                    <button onClick={() => { setSelectedDay("Saturday") }} className={`${selectedDay === "Saturday" ? "font-semibold" : "text-gray-600"} `}>Sat</button>
                </div>
                <div className='w-full pl-5 pr-5'>
                    <div className="flex mt-2">
                        <label className="text-sm flex items-center">
                            <input
                                type="radio"
                                className="mr-1"
                                onChange={() => handleChangingChargeType("Per Hour")}
                                checked={chargePerHour === true}
                            />
                            Per Hour
                        </label>
                        <label className="text-sm flex items-center ml-4">
                            <input
                                type="radio"
                                className="mr-1"
                                onChange={() => handleChangingChargeType("Flat Fee")}
                                checked={chargePerHour === false}
                            />
                            Flat Fee
                        </label>
                    </div>
                </div>
                {chargePerHour ? (
                    <div className="w-full h-56 grid grid-cols-8 mt-1 pl-2 pr-2">
                        {selectedDayData?.map((time, index) => (
                            <div key={index} className="flex flex-col col-span-1 items-center justify-center w-full">
                                <svg onClick={() => addOrRemoveDateTime(`${selectedDay}${index}`)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${lockedDateTime.includes(`${selectedDay}${index}`) ? "hidden" : "block"} w-4 h-4 ml-1 cursor-pointer`}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 1 1 9 0v3.75M3.75 21.75h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H3.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                                </svg>
                                <svg onClick={() => addOrRemoveDateTime(`${selectedDay}${index}`)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${lockedDateTime.includes(`${selectedDay}${index}`) ? "block" : "hidden"} text-red-600 w-4 h-4 ml-1 cursor-pointer`}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                                </svg>
                                <h3 className="text-xs">{PolicyFormTimes[index]}</h3>
                                <input type="number" onChange={(e) => handlePerHourValueChange(e.target.value, index)} value={selectedDayData[index]} className="w-10 text-sm text-center h-6 rounded-[4px] border-[1px] border-gray-400 bg-gray-100"></input>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="mt-2 flex items-center ml-5 pl-2 bg-gray-100 border-[1px] border-gray-500 w-[124px] rounded-lg">
                        <h2 className="mr-1 font-semibold">$</h2>
                        <input type="number" value={flatFeePrice} onChange={(e) => handleFlatFeePriceChange(e.target.value)} className="h-6 w-12 pl-1 pr-1 border-r-[1px] border-l-[1px] border-gray-500"></input>
                        <h2 className="text-sm font-semibold ml-1">/ Hour</h2>
                    </div>
                )}
            </div>
        </>
    )
}

export default ParkingInput;