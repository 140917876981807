import Header from "../globalComponents/header";
import MapContainer from "../globalComponents/map/mapContainer";
import React, { useEffect, useState } from "react";
import { fetchChargerData, fetchUserPolicyNames, fetchPolicyDataByName, fetchPolicyUserRelations, fetchChargerPolicyPassesInfo } from "../helpers/apis";
import { combineChargerData } from "../helpers/dashboard";
import SubMenu from "./paymentComponents/subMenu";
import { mergeArrayValues, combineAPIChargerData } from "../helpers/payment";
import ChargerPolicySection from "./paymentComponents/fixedChargerPolicySection";
import { useSelector } from "react-redux";
import { getChargerIds } from "../redux/selectors";
import { removeChargerIds } from "../redux/reducers/chargersSlice";
import { useDispatch } from "react-redux";
import MapSettings from "./otherComponents/mapSettings";
import useSWR, { mutate } from "swr";
import axios from "axios";
import Cookies from "js-cookie"
import { createPortal } from "react-dom";
import PassManagerQuick from "./paymentComponents/formComponents/passManager/passManagerQuick";
import PassManager from "./paymentComponents/formComponents/passManager/passManager";
import NoAccess from "../globalComponents/noAccess";


function Payment() {

    const [showChargerPolicySection, setShowChargerPolicySection] = useState(false)
    const [showUserPassInfo, setShowUserPassInfo] = useState(false)
    const [animationType, setAnimationType] = useState("in")
    const [createPass, setCreatePass] = useState(false)

    const [selectedChargerID, setSelectedChargerID] = useState([])
    const [selectedPolicyID, setSelectedPolicyID] = useState(-1)
    const [selectedPassesID, setSelectedPassesID] = useState([])

    const [showSettings, setShowSettings] = useState(false)
    const [mapSettings, setMapSettings] = useState("mapbox://styles/mapbox/streets-v10")

    const facility = Cookies.get('intellicharge_username');
    const chargerID = Cookies.get('charger_ID')
    const page_access = Cookies.get('intellicharge_page_access')

    const [chargers, setChargers] = useState([])
    const [policies, setPolicies] = useState([])
    const [passes, setPasses] = useState([])
    const [userPasses, setUserPasses] = useState([])

    const everythingUrl = `https://gmsapi1communication.com:443/chargerpolicy?facility=${facility}`;
    const detailedChargersUrl = `https://gmsapi1communication.com:443/dashData?chargerID=${chargerID}`;
    const userPassInfoUrl = `https://gmsapi1communication.com:443/userpass?facility=${facility}`
    const policyNamesUrl = `https://gmsapi1communication.com:443/allpolicies?facility=${facility}`
    const policyDataUrl = ``

    // if(!page_access.includes('payments')) {
    //     window.location = "/dashboard"
    // }

    const animationClass = {
        "in": 'slideIn',
        "out": 'slideOut'
    }

    function animateSettings() {
        if (!showUserPassInfo) {
            setShowUserPassInfo(true)
            setAnimationType("in")
        } else {
            setAnimationType("out")
            setTimeout(() => {
                setShowUserPassInfo(false)
            }, 150)
        }
    }

    // This is a generic axios fetcher function so for the useSWR hook
    const genericSWRFetcher = async (url) => {
        const token = Cookies.get("jwt_token_intellicharge");
        return axios.get(url, {
            headers: { "Content-Type": "application/json", "x-access-token": `${token}` }
        }).then(res => res.data);
    }

    // This hook fetches the new and improved API that includes chargers, passes, and policies,
    // as well as their relationships.
    const { data: everythingData, error: everythingDataError, isLoading: everythingDataLoading } = useSWR(
        everythingUrl,
        () => genericSWRFetcher(everythingUrl),
        { refreshInterval: 10000 }
    );

    // This hook fetches the chargers with more detail
    const { data: detailedChargers, error: detailedChargersError, isLoading: detailedChargersLoading } = useSWR(
        detailedChargersUrl,
        () => genericSWRFetcher(detailedChargersUrl),
        { refreshInterval: 10000 }
    );

    // This hook fetches the user pass information API
    const { data: userPassInfo, error: userPassInfoError, isLoading: userPassInfoLoading } = useSWR(
        userPassInfoUrl,
        () => genericSWRFetcher(userPassInfoUrl),
        { refreshInterval: 10000 }
    );

    // These two hooks must be called sequentially in order to fetch all of the policies and their info
    const { data: policyNamesData, error: policyNamesUrlError, isLoading: policyNamesUrlLoading } = useSWR(
        policyNamesUrl,
        () => genericSWRFetcher(policyNamesUrl),
    );

    // This function combines the charger data into a more usable format, as well
    // as sets the state for the chargers and passes whenever the hooks refetch
    useEffect(() => {
        if (!detailedChargers || !everythingData) return
        try {
            const simplifiedChargerData = combineChargerData(detailedChargers)
            const combinedChargerData = combineAPIChargerData(everythingData.data, simplifiedChargerData)
            setChargers(combinedChargerData)
            setPasses(everythingData?.data?.passes)
            setUserPasses(userPassInfo)
        } catch (err) {
            console.error("Error combining charger data after fetch", err)
        }
    }, [everythingData, detailedChargers, userPassInfo])

    // By making this a stand alone function I can call it when the mutate function is ran
    async function fetchPolicies() {
        try {
            const policyPromises = policyNamesData.map(name =>
                fetchPolicyDataByName(name.policy).then(
                    data => ({ ...name, data })
                ).catch(error => {
                    console.error('Error fetching policy data:', error);
                    return null;
                })
            );
            const policies = await Promise.all(policyPromises);
            setPolicies(policies)
        } catch (error) {
            console.error("Error fetching policies", error)
        }
    }

    // This useEffect fetches the policy data and handles all data wrangling.
    // The order must be sequential 
    useEffect(() => {
        if (policyNamesUrlLoading || !policyNamesData) return
        fetchPolicies()
    }, [policyNamesData])

    // This function allows for the manual refetch of all useSWR hooks, which
    // is nice for closing a form, and getting instant feedback
    const refreshData = () => {
        mutate(everythingUrl);
        mutate(detailedChargersUrl);
        mutate(userPassInfoUrl)
        mutate(policyNamesUrl)
        setTimeout(() => {
            fetchPolicies()
        }, 500)
    };

    useEffect(() => {
        console.log(policies)
    }, [policies])

    // // This ensures that if a charger is clicked, or if a policy is clicked
    // // the sub-section opens up
    useEffect(() => {
        if (selectedChargerID.length !== 0 || selectedPolicyID !== -1 || selectedPassesID.length !== 0 || createPass) {
            setShowChargerPolicySection(true)
        } else {
            setShowChargerPolicySection(false)
        }
    }, [selectedPolicyID, selectedChargerID, selectedPassesID, createPass])

    return (
        <>
            <Header title={"Charger Settings"} />
            {!page_access.includes('payments') ? (
                <NoAccess/>
            ) : (
            <div className=" h-[calc(100vh-56px)] w-full flex overflow-x-hidden overflow-y-hidden">
                <div className={`z-40 h-full overflow-x-hidden overflow-y-hidden w-[238px] min-w-[238px]`}>
                    <div className={`duration-100 ease-linear h-full w-full border-r-[1px] border-gray-300 overflow-x-hidden overflow-y-hidden`}>
                        <SubMenu
                            setSelectedChargerID={setSelectedChargerID}
                            selectedChargerID={selectedChargerID}
                            setSelectedPolicyID={setSelectedPolicyID}
                            selectedPolicyID={selectedPolicyID}
                            setSelectedPassesID={setSelectedPassesID}
                            selectedPassesID={selectedPassesID}

                            setCreatePass={setCreatePass}

                            chargers={chargers}
                            policies={policies}
                            passes={passes}
                        />
                    </div>
                </div>
                {showUserPassInfo ? (
                    <div className={`fixed z-[8888] shadow-4xl settingsShadow h-full w-[430px] bg-white right-0 animatedWidth ${animationClass[animationType]}`}>
                        <svg onClick={() => animateSettings(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="small-bold-shadow fixed w-10 h-10 bg-white text-black cursor-pointer p-2 -ml-12 mt-2 rounded-full hover:rotate-45 duration-150">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                        <PassManager
                            userPasses={userPasses}
                            refreshData={refreshData}
                        />
                    </div>
                ) : (
                    <div className={`fixed z-[7777] h-[116px] w-48 p-2 rounded-lg shadow-lg bg-white right-2 top-16 `}>
                        <PassManagerQuick
                            userPasses={userPasses}
                            setShowUserPassInfo={animateSettings}
                        />
                    </div>
                )}
                {showChargerPolicySection && (
                    <ChargerPolicySection
                        setSelectedChargerID={setSelectedChargerID}
                        selectedChargerID={selectedChargerID}
                        setSelectedPolicyID={setSelectedPolicyID}
                        selectedPolicyID={selectedPolicyID}
                        setSelectedPassesID={setSelectedPassesID}
                        selectedPassesID={selectedPassesID}

                        setCreatePass={setCreatePass}
                        createPass={createPass}

                        chargers={chargers}
                        policies={policies}
                        passes={passes}

                        refreshData={refreshData}
                    />
                )}
                <div className={`w-full h-full overflow-x-hidden overflow-y-hidden bg-white z-30`}>
                    <div className="w-14 h-14 fixed z-50 right-0 bottom-0 flex items-center justify-center">
                        <button onClick={() => setShowSettings(!showSettings)} className="h-10 w-10 rounded-full flex items-center justify-center bg-secondary transform hover:rotate-45 duration-150">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-7 text-white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 0 1 1.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.559.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.894.149c-.424.07-.764.383-.929.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 0 1-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.398.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 0 1-.12-1.45l.527-.737c.25-.35.272-.806.108-1.204-.165-.397-.506-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.108-1.204l-.526-.738a1.125 1.125 0 0 1 .12-1.45l.773-.773a1.125 1.125 0 0 1 1.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894Z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            </svg>
                        </button>
                    </div>
                    {showSettings && (
                        <MapSettings
                            setMapSettings={setMapSettings}
                        />
                    )}
                    <MapContainer paymentPage={true} dataArray={chargers} mapSettings={mapSettings} />
                </div>
            </div>
            )}
        </>
    )
}

export default Payment;