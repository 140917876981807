
import { useRef, useState } from "react";
import axios from 'axios';
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { setChargerIds } from "../redux/reducers/chargersSlice";
import { useEffect } from "react";
// import Dropdown from 'react-bootstrap/Dropdown';

function Commission() {

    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const [existingFacility, setExistingFacility] = useState(false)
    const [existingLot, setExistingLot] = useState(false)

    const [chargerid, setChargerID] = useState()
    const [facility, setFacility] = useState()
    const [formalFacility, setFormalFacility] = useState()
    const [nicknameFacility, setNicknameFacility] = useState()
    const [lot, setLot] = useState()
    const [formalLot, setFormalLot] = useState()
    const [latitude, setLatitude] = useState()
    const [longitude, setLongitude] = useState()
    const [facilityPassword, setFacilityPassword] = useState("")

    const [facilities, setFacilities] = useState([])
    const [lots, setLots] = useState([])
    const [formalLots, setFormalLots] = useState([])

    const [commissioned, setCommissioned] = useState(false)

    const token = Cookies.get("jwt_token_intellicharge");

    // const facilities = ['1', '2' ,'3','1', '2' ,'3','1', '2' ,'3','1', '2' ,'3','1', '2' ,'3','1', '2' ,'3','1', '2' ,'3','1', '2' ,'3','1', '2' ,'3',]

    // const lots = ['1', '2' ,'3','1', '2' ,'3','1', '2' ,'3','1', '2' ,'3','1', '2' ,'3','1', '2' ,'3','1', '2' ,'3','1', '2' ,'3','1', '2' ,'3',]

    useEffect(() =>{
        getFacilities();
    },[])

    function getFacilities() {
        const url = 'https://gmsapi1communication.com:443/commission/facilityinfo';

        axios.get(url,{
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token
            },
          })
        .then(response => {
            setFacilities(response.data.data)
        })
        .catch(error => {
            console.log(error)
        });
    }

    useEffect(() => {
        if (!existingFacility){
            setFacility()
            setFormalFacility()
            setNicknameFacility()
        }
    }, [existingFacility])

    useEffect(() => {
        if (!existingLot){
            setLot()
            setFormalLot()
        }
    }, [existingLot])

    useEffect(() => {
        if (existingFacility){
            const url = `https://gmsapi1communication.com:443/commission/facilityinfo?facility=${facility}`;
            axios.get(url,{
                headers: {
                  "Content-Type": "application/json",
                  "x-access-token": token
                },
              })
            .then(response => {
                setFormalFacility(response.data.data['formal_facility'])
                setNicknameFacility(response.data.data['facility_nickname'])
                setLots(response.data.data['lots'])
                setFormalLots(response.data.data['lot_formal'])
            })
            .catch(error => {
                console.log(error)
            });
        }
    }, [facility])

    function commissionCharger(){
        // event.preventDefault()
        const verify = verifyCriteria()
        if (verify === false) {
            return;
        }
        let commissionBody = {
            "chargerID":chargerid,
            "facility":facility,
            "formal_facility":formalFacility,
            "nickname_facility":nicknameFacility,
            "lot":lot,
            "formal_lot":formalLot,
            "latitude":latitude,
            "longitude":longitude,
            "password":facilityPassword
        }
        commissionChargerPOST(commissionBody)

        // let username = loginUsernameRef.current.value
        // let password = loginPasswordRef.current.value
        // const loginBody = {
        //     "username": username,
        //     "password": password
        // }
        // fetchUserLogin(loginBody, username, password)

        // add in logic to clear all info after 
    }

    // verify input
    function verifyCriteria() {
        console.log('verify')
        if (!chargerid) {
            setErrorMessage("Error! Charger ID required.")
            setError(true)
            return false;
        }
        if (!facility) {
            setErrorMessage("Error! Facility required.")
            setError(true)
            return false;
        }
        if (!formalFacility) {
            setErrorMessage("Error! Formal Facility required.")
            setError(true)
            return false;
        }
        if (!nicknameFacility) {
            setErrorMessage("Error! Facility Nickname required.")
            setError(true)
            return false;
        }
        if (!lot) {
            setErrorMessage("Error! Lot required.")
            setError(true)
            return false;
        }
        if (!formalLot) {
            setErrorMessage("Error! Formal Lot required.")
            setError(true)
            return false;
        }
        if (!latitude) {
            setErrorMessage("Error! Latitiude required.")
            setError(true)
            return false;
        }
        if (!longitude) {
            setErrorMessage("Error! Longitude required.")
            setError(true)
            return false;
        }
        setError(false)
    }

    async function commissionChargerPOST(commissionData) {
        try {
            const response = await axios.post(`https://gmsapi1communication.com:443/commission/facilityinfo`, commissionData, {
                headers: {
                  "Content-Type": "application/json",
                  "x-access-token": token
                }
              })
            console.log(response)
            if (response.status == 200){
                setCommissioned(true)
                return
            }
        } catch (err) {
            setError(true)
            setErrorMessage("Cannot Commission, the charger already exists")
            console.error("axios POST failed during login" + err)
        }
    }

    return (
        <div className="min-h-screen w-full bg-cover bg-center bg-baseWhite flex overflow-x-hidden">
            <div className="w-[30vw] min-w-[24rem] h-full bg-baseWhite p-6 pr-10 pl-10 box-border">
                <div className="flex align-middle mt-6">
                    <img className="h-8" src="/gridElevatedLogo.svg"></img>
                    <h2 className="h-10 text-2xl font-semibold">Commissioning Page</h2>
                </div>
                <h1 className="mt-8 text-3xl font-normal">Charger Information </h1>
                
                <div className={`${error === true ? "block" : "hidden"} mt-2 p-1 w-full bg-red-300 border-red-600 border-[2px]`}>
                    <h2 className="text-md text-red-800">{errorMessage}</h2>
                </div>
                {/* <div> */}
                    <div className=" mt-3">
                        <h3 className="h-5 text-md mb-1 ml-1">Charger ID (on faceplate of charger)</h3>
                        <input onChange={(e) => (setChargerID(e.target.value))} name="chargerid" placeholder="1234567890" className="h-10 w-full text-lg p-1 border-black border-[1px] rounded-md  mb-2" type="text"></input>

                        <div className="flex align-middle items-center mt-2">
                            <input type="checkbox" className="w-5 h-5" onChange={(event)=>{setExistingFacility(event.target.checked)}}></input>
                            <h3 className="h-5 text-md mb-3 ml-1">Existing User?</h3>
                        </div>

                        {/* If exists, show dropdown to select facility */}

                        {!existingFacility ? 
                        <>
                            <h3 className="h-5 text-md mb-1 ml-1">Facility Account</h3>
                            <input onChange={(e) => (setFacility(e.target.value))} name="facility" placeholder="uofu_cs" className="h-10 w-full text-lg p-1 border-black border-[1px] rounded-md  mb-2" type="text"></input>
                            
                            <h3 className="h-5 text-md mb-1 ml-1">Formal Facility Name</h3>
                            <input onChange={(e) => (setFormalFacility(e.target.value))} name="formal_facility" placeholder="University of Utah" className="h-10 w-full text-lg p-1 border-black border-[1px] rounded-md mb-2" type="text"></input>
                            
                            <h3 className="h-5 text-md mb-1 ml-1">Facility Nickname/Acronym</h3>
                            <input onChange={(e) => (setNicknameFacility(e.target.value))} name="chargerid" placeholder="UofU" className="h-10 w-full text-lg p-1 border-black border-[1px] rounded-md  mb-2" type="text"></input>
                            
                            <h3 className="h-5 text-md mb-1 ml-1">Facility Password</h3>
                            <input onChange={(e) => (setFacilityPassword(e.target.value))} name="chargerid" placeholder="123" className="h-10 w-full text-lg p-1 border-black border-[1px] rounded-md  mb-2" type="text"></input>
                        </>:
                         <div className="flex flex-wrap mt-1">
                            {facilities.map((name, index) => (
                                <button key={index} className="h-8 pl-2 pr-2 text-sm flex mr-1 mb-1 items-center border-[1px] border-gray-300 rounded-md"
                                onClick={()=> setFacility(name)}>{name}</button>
                            ))}
                        </div> }


                        <div className="flex align-middle items-center mt-2">
                            <input type="checkbox" className="w-5 h-5" onChange={(event)=>{setExistingLot(event.target.checked)}}></input>
                            <h3 className="h-5 text-md mb-3 ml-1">Existing Lot?</h3>
                        </div>

                        {/* If true, show lot */}
                        {!existingLot ? 
                        <>
                            <h3 className="h-5 text-md mb-1 ml-1">Parking Lot Name</h3>
                            <input onChange={(e) => (setLot(e.target.value))} name="lot" placeholder="Shoreline" className="h-10 w-full text-lg p-1 border-black border-[1px] rounded-md  mb-2" type="text"></input>
                            
                            <h3 className="h-5 text-md mb-1 ml-1">Formal Parking Lot Name</h3>
                            <input onChange={(e) => (setFormalLot(e.target.value))} name="formal_lot" placeholder="Shoreline Ridge Parking Garage" className="h-10 w-full text-lg p-1 border-black border-[1px] rounded-md  mb-2" type="text"></input>
                            
                        </>:
                        <div className="flex flex-wrap mt-1">
                            {lots.map((name, index) => (
                                <button key={index} className="h-8 pl-2 pr-2 text-sm flex mr-1 mb-1 items-center border-[1px] border-gray-300 rounded-md"
                                onClick={()=>{
                                    setLot(lots[index])
                                    setFormalLot(formalLots[index])
                                }}>{name}</button>
                            ))}
                        </div>}

                        <h3 className="h-5 text-md mb-1 ml-1">Charger Latitude (plesae include as many decimal places as possible)</h3>
                        <input onChange={(e) => (setLatitude(e.target.value))} name="latitude" placeholder="40.123456" className="h-10 w-full text-lg p-1 border-black border-[1px] rounded-md mb-2" type="text"></input>
                        
                        <h3 className="h-5 text-md mb-1 ml-1">Charger Longitude (plesae include as many decimal places as possible)</h3>
                        <input onChange={(e) => (setLongitude(e.target.value))} name="longitude" placeholder="-111.123456" className="h-10 w-full text-lg p-1 border-black border-[1px] rounded-md mb-10" type="text"></input>
                    </div>
                    {/* <Link to="/dashboard"> */}

                    <h2>Charger ID: {chargerid}</h2>
                    <h2>Facility Account: {facility}</h2>
                    <h2>Facility Formal: {formalFacility}</h2>
                    <h2>Facility Nickname: {nicknameFacility}</h2>
                    <h2>Parking Lot: {lot}</h2>
                    <h2>Lot Formal: {formalLot}</h2>
                    <h2>Coordinates: {latitude},{longitude}</h2>

                    <button className="w-full h-10 bg-blue-700 mt-2 rounded-md text-white text-lg font-bold shadow-md" onClick={() => commissionCharger()}>
                        Commission Charger
                    </button>

                    <div className={`${commissioned === true ? "block" : "hidden"} mt-2 p-1 w-full bg-green-300 border-green-600 border-[2px]`}>
                        <h2 className="text-md text-green-800">Charger Commissioned</h2>
                    </div>
                    {/* </Link> */}
                {/* </form> */}
            </div>

        </div>
    )
}

export default Commission;