import React, { useState, useEffect, useRef } from "react"
import { getCoords } from '../../redux/selectors';
import { useSelector } from 'react-redux';
import mapboxgl from 'mapbox-gl'
import Map from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { convertDecimalToTime } from "../../helpers/timeFunctions";

// This should be moved to env
const accessToken = 'pk.eyJ1IjoiYWpmYXJsZXk5OSIsImEiOiJjbHEya2lna2cwMHl6MmlwY3ZtaTNvaDZjIn0.MCauwooWDnlP59tyXp1coA'

function ClusterMap({
    mapDimensions,
    chargerDataArray
}) {

    const [mapOptions, setMapOptions] = useState({
        latitude: chargerDataArray[0]?.lat? parseFloat(chargerDataArray[0]?.lat) : 40.753762,
        longitude: chargerDataArray[0]?.lng? parseFloat(chargerDataArray[0]?.lng) : -111.831477,
        width: 0,
        height: 0,
        zoom: 14
    })
    const [geoJsonData, setGeoJsonData] = useState(null)
    // const openedChargersRedux = useSelector(getChargerIds)
    const selectedCoords = useSelector(getCoords)
    const mapRef = useRef()

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.flyTo({
                center: [selectedCoords.longitude, selectedCoords.latitude],
                essential: true, 
                zoom: 20
            });
        }
    }, [selectedCoords])

    // console.log(chargerDataArray)

    // Ensure width and height of map is updated if screen size changes
    useEffect(() => {
        setMapOptions(prev => ({ ...prev, width: mapDimensions.width, height: mapDimensions.height }))
        if (mapRef.current) {
            mapRef.current.resize()
        }
    }, [mapDimensions])

    // This useEffect listens to hear if any new mapMarkers are 
    // added to the global state in redux, and turns the markers
    // into geoJson which the map can use
    useEffect(() => {
        if (!chargerDataArray || chargerDataArray.length === 0) return;
        console.log(chargerDataArray)
        const now = new Date();
        let hours = now.getHours();
        const minutes = now.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;
        const timeString = `${hours}:${minutesFormatted} ${ampm}`;

        // console.log(chargerDataArray)
        let geojsonData = {
            type: "FeatureCollection",
            features: chargerDataArray.map(point => ({
                type: "Feature",
                properties: {
                    id: point.id,
                    name: point.name,
                    lot: point.lot,
                    status: point.status,
                    lastPowerTime: point.power.length > 0 ? convertDecimalToTime(point.power[point.power.length - 1].time):timeString,
                    lastPowerValue: point.power.length > 0 ? (Number(point.power[point.power.length - 1].value) * 208) / 1000: 0
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        point.lng !== undefined ? point.lng : 0,
                        point.lat !== undefined ? point.lat : 0
                    ]
                }
            }))
        };
        setGeoJsonData(geojsonData)
    }, [chargerDataArray])

    useEffect(() => {
        if (mapRef.current) {
            const map = mapRef.current.getMap();

            if(chargerDataArray[0].lat && chargerDataArray[0].lng) {
                mapRef.current.flyTo({
                    center: [parseFloat(chargerDataArray[0].lng), parseFloat(chargerDataArray[0].lat)],
                    essential: true
                })
            }

            // This function creates clusters but instead of group them by proximity,
            // this groups them together based on their lot
            const createClustersByLot = (features) => {
                const clusters = {};
                features.forEach((feature) => {
                    const lotName = feature.properties.lot;
                    if (!clusters[lotName]) {
                        clusters[lotName] = {
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: [0, 0],
                            },
                            properties: {
                                lot: lotName,
                                point_count: 0,
                                points: [],
                            },
                        };
                    }

                    // Add the point to the lot cluster
                    clusters[lotName].properties.points.push(feature);
                    clusters[lotName].properties.point_count += 1;

                    // Calculate centroid for the lot (or another representative point)
                    clusters[lotName].geometry.coordinates = calculateCentroid(
                        clusters[lotName].properties.points
                    );
                });

                return Object.values(clusters);
            };

            // This function calculates the central point for all of the chargers
            // sharing a cluster name
            const calculateCentroid = (points) => {
                let totalX = 0,
                    totalY = 0;
                points.forEach((point) => {
                    totalX += parseFloat(point.geometry.coordinates[0]);
                    totalY += parseFloat(point.geometry.coordinates[1]);
                });
                // console.log(totalX)
                // console.log(totalY)
                return [totalX / points.length, totalY / points.length];
            };

            // This is the function that draws the map and adds all layers
            const addSourcesAndLayers = () => {
                // if (!map.getSource('sensorMarkers')) {
                    if (map.getLayer('unclustered-point')) map.removeLayer('unclustered-point');
                    if (map.getLayer('clusters')) map.removeLayer('clusters');
                    if (map.getLayer('cluster-name')) map.removeLayer('cluster-name');
                    if (map.getSource('sensorMarkers')) map.removeSource('sensorMarkers');
                    if (map.getSource('sensorMarkersUnclustered')) map.removeSource('sensorMarkersUnclustered');
                    const clusteredData = createClustersByLot(geoJsonData.features);
                    // console.log(clusteredData)

                    // DATA LAYERS & IMPORTS
                    // DATA LAYERS & IMPORTS
                    // DATA LAYERS & IMPORTS

                    // Import the individual chargers themselves for maximum zoom
                    map.addSource('sensorMarkersUnclustered', {
                        type: 'geojson',
                        data: geoJsonData,
                    });
                    // Import the grouped chargers together for the clusters
                    map.addSource('sensorMarkers', {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            features: clusteredData,
                        },
                    });

                    // Adds the ungrouped chargers layer to the map for when users
                    // are all the way scrolled in 
                    map.addLayer({
                        id: 'unclustered-point',
                        type: 'circle',
                        source: 'sensorMarkersUnclustered',
                        filter: ['!', ['has', 'point_count']],
                        minzoom: 17,
                        paint: {
                            'circle-color': [
                                'case',
                                ['==', ['get', 'status'], 'charging'],
                                '#DCFCE7',
                                ['==', ['get', 'status'], 'connected'],
                                '#DBEAFE',
                                ['==', ['get', 'status'], 'sleeping'],
                                '#DBEAFE',
                                ['==', ['get', 'status'], 'not connected'],
                                '#F9FAFB',
                                ['==', ['get', 'status'], 'OFFLINE'],
                                '#FEF2F2',
                                '#000000', // Default color if neither
                            ],
                            'circle-stroke-color': [
                                'case',
                                ['==', ['get', 'status'], 'charging'],
                                '#15803D',
                                ['==', ['get', 'status'], 'connected'],
                                '#1D4ED8',
                                ['==', ['get', 'status'], 'sleeping'],
                                '#1D4ED8',
                                ['==', ['get', 'status'], 'not connected'],
                                '#374151',
                                ['==', ['get', 'status'], 'OFFLINE'],
                                '#B91C1C',
                                '#000000', // Default color if neither
                            ],
                            'circle-radius': 10,
                            'circle-stroke-width': 1,
                        },
                    });

                    // This adds the clusters to the map
                    map.addLayer({
                        id: 'clusters',
                        type: 'circle',
                        source: 'sensorMarkers',
                        minzoom: 0,
                        maxzoom: 17,
                        paint: {
                            'circle-color': '#89b4f9',
                            'circle-radius': 20,
                        },
                    });


                    // This adds the cluster name to the map
                    map.addLayer({
                        id: 'cluster-name',
                        type: 'symbol',
                        source: 'sensorMarkers',
                        minzoom: 0,
                        maxzoom: 17,
                        layout: {
                            'text-field': ['get', 'lot'],
                            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                            'text-size': 14,
                        },
                    });

                    // POPUPS
                    // POPUPS
                    // POPUPS

                    let popup = new mapboxgl.Popup({
                        closeButton: false,
                        closeOnClick: false
                    });

                    // This handles the popup and content for each individual point
                    map.on('mouseenter', 'unclustered-point', (e) => {
                        map.getCanvas().style.cursor = 'pointer';

                        const coordinates = e.features[0].geometry.coordinates.slice();
                        const name = e.features[0].properties.name;
                        const status = e.features[0].properties.status
                        const power = e.features[0].properties.lastPowerValue
                        const time = e.features[0].properties.lastPowerTime

                        let statusContent;

                        console.log(e.features[0])

                        if (status === "charging") { statusContent = '<h2 style="font-size: 0.9rem; color: #15803D; font-weight: 600;">Charging</h2>' }
                        if (status === "connected" || status === "sleeping") { statusContent = '<h2 style="font-size: 0.9rem; color: #1D4ED8; font-weight: 600;">Sleeping</h2>' }
                        if (status === "not connected") { statusContent = '<h2 style="font-size: 0.9rem; color: #374151; font-weight: 600;">Not Connected</h2>' }
                        if (status === "OFFLINE") { statusContent = '<h2 style="font-size: 0.9rem; color: #B91C1C; font-weight: 600;">Offline</h2>' }

                        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                        }


                        popup.setLngLat(coordinates)
                            // .setHTML(`<div style="width: 100px; height: 90px; background-color: green">${name}</div>`)
                            .setHTML(`
                            <div style="padding: 0.1rem; background-color: white; width: 200px">
                                <h2 style="font-size: 1.0rem; font-weight: 600;">${name}</h2>
                                <h2 style="font-size: 0.8rem; color: #4B5563; margin-top: 8px;">Status:</h2>
                                ${statusContent}
                                <h2 style="font-size: 0.8rem; color: #4B5563; margin-top: 8px;">Current Power:</h2>
                                <h2 style="font-size: 0.9rem; color: #000; font-weight: 600;">${power.toFixed(2)} kW, ${time}</h2>
                            </div>
                            `)
                            .addTo(map);
                    });

                    // This handles the popup for a clustered point
                    map.on('mouseenter', 'clusters', async (e) => {
                        map.getCanvas().style.cursor = 'pointer';

                        const coordinates = e.features[0].geometry.coordinates.slice();
                        // const clusterId = e.features[0].properties.cluster_id;
                        const source = map.getSource('sensorMarkers');
                        const content = source._options.data.features[0].properties.points

                        let statusContent;


                        if (content.length > 0) {
                            content.forEach((point) => {
                                if (point.properties.status === "charging") { statusContent += `
                                <h2 style="font-size: 0.9rem; font-weight: 600;">${point.properties.name}</h2>             
                                <h3 style="color: #15803D; line-height: 13px; margin-bottom: 6px;">Charging</h3>` }
                                if (point.properties.status === "connected" || point.properties.status === "sleeping") { statusContent += `
                                <h2 style="font-size: 0.9rem; font-weight: 600;">${point.properties.name}</h2>          
                                <h3 style="color: #1D4ED8; line-height: 13px; margin-bottom: 6px;">Sleeping</h3>` }
                                if (point.properties.status === "not connected") { statusContent += `
                                <h2 style="font-size: 0.9rem; font-weight: 600;">${point.properties.name}</h2>           
                                <h3 style="color: #374151; line-height: 13px; margin-bottom: 6px;">Not Connected</h3>` }
                                if (point.properties.status === "OFFLINE") { statusContent += `
                                <h2 style="font-size: 0.9rem; font-weight: 600;">${point.properties.name}</h2>           
                                <h3 style="color: #B91C1C; line-height: 13px; margin-bottom: 6px;">Offline</h3>` }
                            })
                        }

                        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                        }


                        popup.setLngLat(coordinates)
                            .setHTML(`
                            <div style="padding: 0.1rem; background-color: white;>
                                <h2 style="font-size: 0.9rem; font-weight: 600;">${content[0].properties.lot}</h2>
                                ${statusContent}
                            </div>
                            `)
                            .addTo(map);
                    });

                    map.on('mouseleave', 'unclustered-point', () => {
                        map.getCanvas().style.cursor = '';
                        popup.remove();
                    });

                    map.on('mouseleave', 'clusters', () => {
                        map.getCanvas().style.cursor = '';
                        popup.remove();
                    });

                // }
            };

            if (map.isStyleLoaded()) {
                addSourcesAndLayers();
            } else {
                map.on('style.load', addSourcesAndLayers);
            }
            return () => {
                map.off('style.load', addSourcesAndLayers);
            };
        }
    }, [geoJsonData]);


    return (
        <Map ref={mapRef} initialViewState={{ latitude: mapOptions.latitude, longitude: mapOptions.longitude, zoom: mapOptions.zoom }}
            // mapStyle="mapbox://styles/ajfarley99/cl62p74a8000015nyql5448dx"
            mapStyle="mapbox://styles/mapbox/streets-v10"
            // mapStyle="mapbox://styles/mapbox/dark-v10"
            mapboxAccessToken={accessToken}
            style={{ height: mapDimensions.height, width: mapDimensions.width, borderRadius: '6px' }} >
        </Map>
    )
}

export default ClusterMap;