
function MapSettings({
    setMapSettings
}) {
    return (
        <div className="w-96 h-56 fixed z-40 right-0 bottom-14 p-2 pt-4 pb-0 ">
            <div className="w-full h-full p-3 policyPageMapSettingsScrollBar overflow-y-scroll bg-[#3F5667] bg-opacity-95 border-[1px] border-secondary rounded-xl   ">
                <h2 className="text-white font-semibold">Style:</h2>
                <div className="flex w-full h-16 mt-1">
                    <button onClick={() => setMapSettings("mapbox://styles/mapbox/streets-v10")} className="flex items-center hover:scale-[99%] duration-100 cursor-pointer h-16 w-16 justify-center rounded-lg border-[0px] mr-3 border-secondary">
                        <img className="w-full h-full rounded-md" src="/LightModeMap.png" />
                    </button>
                    <button onClick={() => setMapSettings("mapbox://styles/ajfarley99/cl62p74a8000015nyql5448dx")} className="flex items-center hover:scale-[99%] duration-100 cursor-pointer h-16 w-16 justify-center rounded-lg border-[0px] mr-3 border-secondary">
                        <img className="w-full h-full rounded-md" src="/SateliteMap.png" />
                    </button>
                    <button onClick={() => setMapSettings("mapbox://styles/mapbox/dark-v10")} className="flex items-center hover:scale-[99%] duration-100 cursor-pointer h-16 w-16 justify-center rounded-lg border-[0px] mr-3 border-secondary">
                        <img className="w-full h-full rounded-md" src="/DarkModeMap.png" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default MapSettings