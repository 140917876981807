import React from "react";

function SidebarButton({ 
    settings, 
    sidebarWidth, 
    svgPath, 
    title 
}) {
    return (
        // <div className="flex h-10 items-center ml-2.5 p-2 duration-150 ease-in-out">
        <div className="flex h-10 items-center ml-2.5 p-2 duration-150 ease-in-out">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                // dataslot="icon"
                className="w-7 h-7 text-white"
            >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d={svgPath}
                />
            </svg>

            <h2
                className={`${sidebarWidth === false && settings === false
                    ? "hidden"
                    : "block"
                    } text-white ml-3 text-lg font-semibold`}
            >
                {title}
            </h2>
        </div>
    )
}

export default SidebarButton;


