import React, { useState, useEffect } from "react";

import PolicyForm from "./formComponents/policyForm";
import SelectedCharger from "./formComponents/selectedCharger";
import SelectedPass from "./formComponents/selectedPass";
import CreatePass from "./formComponents/createPass";

function ChargerPolicySection({
    setSelectedChargerID,
    selectedChargerID,
    setSelectedPolicyID,
    selectedPolicyID,
    setSelectedPassesID,
    selectedPassesID,

    setCreatePass,
    createPass,

    chargers,
    policies,
    passes,

    refreshData
}) {

    const [chargersToBeShown, setChargersToBeShown] = useState([])
    const [policyToBeShown, setPolicyToBeShown] = useState({})
    const [passesToBeShown, setPassesToBeShown] = useState([])
    const [newPolicy, setNewPolicy] = useState(false)

    // This function compares all of the selected chargers to the 
    // chargers array and only selects the ones with matching ID's
    useEffect(() => {
        if (chargers.length === 0) return
        if (selectedChargerID.length === 0) return
        let tempArray = []
        chargers.forEach((charger) => {
            if (selectedChargerID.includes(charger.id)) {
                tempArray.push(charger)
            }
        })
        setChargersToBeShown(tempArray)
    }, [chargers, selectedChargerID])

    // This function compares all of the policies to the selected policy
    // id, ensuring that only the correct policy is sent 
    useEffect(() => {
        if (policies.length === 0) return
        if (selectedPolicyID === -1) return
        if (selectedPolicyID === -2) {
            setNewPolicy(true)
            return
        } else {
            setNewPolicy(false)
        }
        policies.forEach((policy) => {
            if (policy.id === selectedPolicyID) {
                setPolicyToBeShown(policy)
            }
        })
    }, [policies, selectedPolicyID])

    // This function loops through all of the passes, and gets the ones
    // with matching ID's and adds them to state for the "Your Passes" section
    useEffect(() => {
        if (passes.length === 0) return
        if (selectedPassesID.length === 0) return
        let tempArray = []
        passes.forEach((pass) => {
            if (selectedPassesID.includes(pass.id)) {
                tempArray.push(pass)
            }
        })
        setPassesToBeShown(tempArray)
    }, [passes, selectedPassesID])

    return (
        <div className={`flex max-w-[830px] h-full fixed z-40 bottom-0 top-[56px] left-[304px] p-2 gap-2`}>
            {selectedPolicyID !== -1 && (
                <div className={`min-w-[505px] max-w-[505px] max-h-[calc(100%-56px)] massive-shadow row-span-4 bg-white rounded-lg border-[1px] border-gray-400`}>
                    <PolicyForm
                        selectedPolicyID={selectedPolicyID}
                        setSelectedPolicyID={setSelectedPolicyID}
                        policies={policies}
                        policyToBeShown={policyToBeShown}
                        newPolicy={newPolicy}
                        passes={passes}
                        refreshData={refreshData}
                        chargers={chargers} />
                </div>
            )}
            <div>
                {selectedChargerID?.length > 0 && (
                    <div className="w-full max-w-[300px] min-w-[300px] max-h-[calc(100%-56px)] h-auto massive-shadow bg-white gap-2 overflow-y-scroll policyFormScrollBar rounded-lg p-2 pt-1 border-[1px] border-gray-400 ">
                         <div className="flex items-center justify-between">
                            <h2 className="font-semibold mb-1">Selected Chargers</h2>
                            <button onClick={() => setSelectedChargerID([])}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        {chargersToBeShown?.map((charger, index) => (
                            <div key={index} className="bg-white rounded-lg border-[1px] border-gray-400 mb-2">
                                <SelectedCharger
                                    setSelectedChargerID={setSelectedChargerID}
                                    selectedCharger={charger}
                                    policies={policies}
                                    passes={passes}
                                    refreshData={refreshData}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div>
                {createPass && (
                    <CreatePass
                        setCreatePass={setCreatePass}
                        policies={policies}
                        passes={passes}
                        refreshData={refreshData}
                    />
                )}
                {selectedPassesID.length > 0 && (
                    <div className={`max-h-[calc(100%-56px)] w-full min-w-[300px] bg-white massive-shadow gap-2 overflow-y-scroll policyFormScrollBar rounded-lg p-2 pt-1 border-[1px] border-gray-400`}>
                        <div className="flex items-center justify-between">
                            <h2 className="font-semibold mb-1">Your Passes</h2>
                            <button onClick={() => setSelectedPassesID([])}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        {passesToBeShown?.map((pass, index) => (
                            <div key={index} className=" bg-white rounded-lg border-[1px] border-gray-400 mb-2">
                                <SelectedPass
                                    setSelectedPassesID={setSelectedPassesID}
                                    selectedPass={pass}
                                    policies={policies}
                                    chargers={chargers}
                                    refreshData={refreshData}
                                    setAffectedPassesNewPolicy={function () { }}
                                    index={index}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default ChargerPolicySection;