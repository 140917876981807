import React, { useState, useEffect, Fragment } from "react"
import { userPassUpdate } from "../../../../helpers/apis"
import "react-datepicker/dist/react-datepicker.css";
import { debounce } from "lodash"

import Requests from "./requests";
import { all } from "axios";

function PassManager({
    userPasses,
    refreshData
}) {

    const [arrayVersionUserPasses, setArrayVersionUserPasses] = useState([])

    const [allSearchableObjects, setAllSearchableObjects] = useState([])
    const [searchResults, setSearchResults] = useState([])

    const [pending, setPending] = useState(true)
    const [accepted, setAccepted] = useState(false)
    const [denied, setDenied] = useState(false)

    useEffect(() => {
        if (arrayVersionUserPasses.length > 0) { setSearchResults(arrayVersionUserPasses) }
    }, [arrayVersionUserPasses])

    // This makes the user passes object mappable for the markup,
    // it also sorts the objects in each child array by their status
    useEffect(() => {
        if (!userPasses.data) return;
        try {
            let allRequests = [];
            let allKeysAndUsers = []
            Object.keys(userPasses.data).forEach((key) => {
                let temp = [];
                let array = userPasses.data[key];
                // This adds the charger as a key value pair in the request object
                array.forEach((item) => {
                    item.charger = key
                    temp.push(item);
                });
                // This sorts the request objects by their status
                temp.sort((a, b) => {
                    const statusOrder = { null: 1, accepted: 2, denied: 3 };
                    return statusOrder[a.status] - statusOrder[b.status];
                });
                // These two functions create an array of searchable strings.
                // They include usernames, and all of the passes
                const usernames = userPasses.data[key].map(request => request.username)
                const responses = userPasses.data[key].map(request => request.response)
                allKeysAndUsers.push(key)
                allKeysAndUsers.push(usernames)
                allKeysAndUsers.push(responses)
                if (temp.length === 0) {
                    return
                } else {
                    allRequests.push(temp);
                }
            });
            const flattenedArray = allKeysAndUsers.flat(Infinity)
            const noDups = [...new Set(flattenedArray)]
            setAllSearchableObjects(noDups)
            setArrayVersionUserPasses(allRequests);
        } catch (error) {
            console.error("Error creating massive array for userPasses", error);
        }
    }, [userPasses]);

    // This handles changes to the search input, and filters by passes, and users
    const changeSearchResults = debounce((string) => {
        const searchString = string.toLowerCase()
        const filteredResults = allSearchableObjects.filter(
            object => object.toLowerCase().includes(searchString.toLowerCase())
        )
        if (arrayVersionUserPasses.length > 0) {
            let allMatches = []
            arrayVersionUserPasses.forEach((pass) => {
                let temp = []
                pass.forEach((object) => {
                    if (filteredResults.includes(object.charger) || filteredResults.includes(object.username) || filteredResults.includes(object.response)) {
                        temp.push(object)
                    }
                })
                allMatches.push(temp)
            })
            setSearchResults(allMatches)
            let isEmpty = true
            allMatches.forEach((match) => {
                if (match.length > 0) {
                    isEmpty = false
                }
            })
            if (isEmpty) {
                setSearchResults([])
            }
        }
    }, 300)

    return (
        <div className="w-full h-full bg-baseWhite settingsShadow border-t-[1px] border-l-[1px] border-gray-300">
            <div className="bg-white shadow-sm">
                <h2 className="font-semibold text-lg pt-2 p-3 pb-2 bg-white">Pass Manager</h2>
                <input onChange={(e) => changeSearchResults(e.target.value)} type="text" placeholder="Search by pass, username, or UID" className={`mb-3 text-sm ml-3 pl-2 pr-2 h-8 w-64 rounded-full border-[1px] border-gray-400`}></input>
                <div className="flex w-full h-12 items-center pt-1k bg-white pl-3 pr-3 -mt-3">
                    <input checked={pending} onChange={() => setPending(!pending)} type="checkbox" id="pendingCheck" className=" h-4 w-4 ml-1" />
                    <label htmlFor="pendingCheck" className="flex items-center cursor-pointer h-6 text-sm bg-yellow-50 border-[1px] border-yellow-600 rounded-md pl-0 pr-2 ml-1">
                        <span className="ml-2 text-yellow-600">Pending</span>
                    </label>
                    <input checked={accepted} onChange={() => setAccepted(!accepted)} type="checkbox" id="acceptedCheck" className="h-4 w-4 ml-4" />
                    <label htmlFor="acceptedCheck" className="flex items-center cursor-pointer h-6 text-sm bg-green-50 border-[1px] border-green-600 rounded-md pl-0 pr-2 ml-1">
                        <span className="ml-2 text-green-600">Accepted</span>
                    </label>
                    <input checked={denied} onChange={() => setDenied(!denied)} type="checkbox" id="deniedCheck" className="h-4 w-4 ml-4" />
                    <label htmlFor="deniedCheck" className="flex items-center cursor-pointer h-6 text-sm bg-red-50 border-[1px] border-red-600 rounded-md pl-0 pr-2 ml-1">
                        <span className="ml-2 text-red-600">Denied</span>
                    </label>
                </div>
            </div>
            <div className="h-[calc(100vh-181px)] w-full overflow-y-scroll dashboardScrollBar p-2">
                {searchResults.length === 0 && (
                    <div className="w-full border-[1px] border-yellow-700 flex p-2 bg-yellow-50 rounded-md">
                        <h2 className="text-sm text-yellow-700">No passes or usernames meet search criteria!</h2>
                    </div>
                )}
                {searchResults.map((pass, index) => {
                    const pendingRequests = pass.filter((request) => request.status !== "accepted" && request.status !== "denied");
                    const acceptedRequests = pass.filter((request) => request.status === "accepted");
                    const deniedRequests = pass.filter((request) => request.status === "denied");

                    return (
                        <Fragment key={index}>
                            {pass[0]?.charger && (
                                <>
                                    <h2 className="text-[15px] font-semibold ml-2 italic">{pass[0]?.charger}</h2>
                                    <div className="w-full border-[1px] bg-white border-gray-300 overflow-y-scroll dashboardScrollBar max-h-96 rounded-md mb-3 mt-1 pt-1 pb-1">
                                        {pendingRequests.length > 0 && pending && (
                                            <Requests
                                                requests={pendingRequests}
                                                refreshData={refreshData}
                                                type={"pending"}
                                            />
                                        )}
                                        {acceptedRequests.length > 0 && accepted && (
                                            <Requests
                                                requests={acceptedRequests}
                                                refreshData={refreshData}
                                                type={"accepted"}
                                            />
                                        )}
                                        {deniedRequests.length > 0 && denied && (
                                            <Requests
                                                requests={deniedRequests}
                                                refreshData={refreshData}
                                                type={"denied"}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                        </Fragment>
                    );
                })}
            </div>
        </div>
    )
}

export default PassManager